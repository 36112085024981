import React, { useEffect, useState, useCallback } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import {
  deflateErrors,
  isBadRequest,
  isJSON,
  isSeller,
  validatePassword,
} from "../../services/Utilities";
import * as Yup from "yup";
import {
  Authenticate,
  AuthenticateWithGoogle,
} from "../../services/UserService";
import { GoogleLogin } from "react-google-login";
import classes from "./LoginModule.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { gapi } from "gapi-script";
import { PasswordGroup } from "../../components/PasswordGroup";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import Alert from "../../components/UI/Alert";
const GoogleReCaptchaComponent = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [serverErrors, setServerErrors] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const [captchaVerified, setCaptchaVerified] = useState(true);
  const [emailVerified, setEmailVerified] = useState();

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha("register");
      setCaptchaToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "email",
        plugin_name: "Bidly Client",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const schema = Yup.object().shape({
    email: Yup.string()
      //.email("Not a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
  });

  const initialValue = {
    email: email,
    password: "",
    captchaToken: "",
  };

  const loginUser = useCallback(
    async (values) => {
      ctx.showProgress(true);
      setCaptchaVerified(executeRecaptcha);
      if (!executeRecaptcha) {
        return;
      }

      const handleReCaptchaVerify = async () => {
        const token = await executeRecaptcha("register");
        let payload = {
          username: values.email,
          password: values.password,
          captchaToken: token,
        };

        Authenticate(payload)
          .then((res) => {
            console.log(res);
            ctx.showProgress(false);
            if (location?.state?.location?.pathname) {
              navigate(location?.state?.location?.pathname);
            } else {
              navigate("/dashboard");
            }
          })
          .catch((err) => {
            ctx.showProgress(false);
            console.log("Error Message: ", err);
            if (!isJSON(err.message)) {
              navigate("/error");
              return;
            }

            if (!isBadRequest(err.message)) return;

            const error = JSON.parse(err.message);
            if (error.status === "BAD_REQUEST") {
              if (error.data.status === "VALIDATION_FAILED") {
                setServerErrors(deflateErrors(error.data.errors));
              } else if (
                error.data.status === "NOT_FOUND" ||
                error.data.status === "BAD_CREDENTIALS"
              ) {
                setServerErrors({ password: error.data.message });
              } else if (error.data.status === "LINK_NOT_VERIFIED") {
                setEmailVerified(error.data.message);
              }
            } else {
              navigate("/error");
            }
          });
      };
      handleReCaptchaVerify();
    },
    [executeRecaptcha]
  );

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="offset-lg-4 offset-md-4 col-lg-4 col-md-4 col-sm-12">
            <div className="col-md-12">
              <h2 className="title-1 mb-4 mt-2">Login</h2>
            </div>
            <div className="col-md-12 mb-2">
              <BHotrizontalProgress progress={23} />
              <h5 className="mt-3">Enter credentials to login</h5>
            </div>
            <div className="col-md-12 mt-5">
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={schema}
                onSubmit={(values) => {
                  loginUser(values);
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  validateForm,
                  setFieldValue,
                }) => (
                  <form>
                    {!captchaVerified && (
                      <Alert
                        type="error"
                        children="There was an error trying to send your message. Please try again later."
                      />
                    )}

                    {emailVerified && (
                      <Alert type="error" children={emailVerified} />
                    )}
                    <div className="js-form-message mb-4">
                      <label className="form-label">Email Address</label>
                      <Field
                        className="form-control"
                        type="text"
                        name="email"
                        component={ZInputComponent}
                        placeholder="Enter Email Address"
                        serverError={serverErrors && serverErrors.email}
                      />
                    </div>
                    <div className="js-form-message">
                      <label className="form-label">Password</label>
                      <Field
                        className="psw-field"
                        name="password"
                        component={PasswordGroup}
                        serverError={serverErrors && serverErrors.password}
                        placeholder="Password"
                      />
                    </div>
                    <div className="row mt-2 mb-3">
                      <div className="d-inline col-6">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="rememberMe"
                            id="rememberMe"
                            onChange={() => {}}
                          />
                          <label
                            className="form-check-label pg-1"
                            htmlFor="rememberMe"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div>
                      <div className="d-inline col-6 text-right">
                        <Link
                          className="text-decoration-underline"
                          to="/forgot-password"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="row mb-3">
                      {/* <div className="d-inline p-3 col-md-6">
                        <GoogleLogin
                          clientId={GOOGLE_CLIENT_ID}
                          buttonText="Sign in with Google"
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                          cookiePolicy={"single_host_origin"}
                          className="google-btn btn-block"
                          prompt="consent"
                        />
                      </div> */}
                      <div className="d-inline p-3 col-md-6">
                        <Link
                          className="btn-block btn btn-primary-dark-w px-5 float-right"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                            }
                            handleSubmit(e);
                          }}
                        >
                          LOGIN
                        </Link>
                      </div>
                    </div>
                    {/* <div className="row mb-3">
                      <div className="col-md-12">
                        <h6 className="text-center mt-2 mb-4">
                          Don’t have an account?
                        </h6>
                      </div>
                      <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12">
                        <Link
                          to="/account-type"
                          className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                        >
                          Register Now!
                        </Link>
                      </div>
                    </div> */}
                  </form>
                )}
              </Formik>
            </div>
            {/* <div className="col-md-6 br-left p-3">
            <div className="col-md-12">
              <h2 className="title">
                Don't have an account?
              </h2>
            </div>
            <div className="col-md-12 mb-2">
              <BHotrizontalProgress progress={33} />
            </div>
            <div className="col-md-12">
                <p className="p-2">
                Creating an account is easy, helps us give you a more personalised
                experience.
                </p>
                <p className="p-2">
                You can create bidly account as bider or as seller. For seller you
                need to be a goverment registered company and have rights to sell
                item.
                </p>
                <Link className="link-v1">Learn More...</Link>
            </div>
            <div className="col-md-4">
              <Link
                to="/account-type"
                className="reg-btn btn-primary transition-3d-hover mt-7"
              >
                REGISTER NOW!
              </Link>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GoogleReCaptchaComponent;
