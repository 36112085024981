import { Auth_SERVICE_URL, Auth_SERVICE_GOOGLE_URL } from "../Constants";
import { simpleFetch } from "./fetch";
import store from "../redux/store";
import { actionTypes } from "../pages/_redux/authRedux";

export const Authenticate = async (requestBody) => {
  const response = await simpleFetch(`${Auth_SERVICE_URL}`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    console.log(res);
    store.dispatch({
      type: actionTypes.Login,
      payload: {
        token: res.accessToken,
        refreshToken: res.refreshToken,
        roles: res.roles,
        user: {
          name: res.fullName,
          username: res.userName,
          type: res.userType,
        },
      },
    });
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const AuthenticateWithGoogle = async (requestBody) => {
  const response = await simpleFetch(`${Auth_SERVICE_GOOGLE_URL}`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    store.dispatch({
      type: actionTypes.Login,
      payload: {
        token: res.accessToken,
        refreshToken: res.refreshToken,
        user: {
          name: res.fullName,
          username: res.userName,
          type: res.userType,
        },
      },
    });
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};
