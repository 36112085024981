import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  AUCTION_ITEM_SALES_REPORT_URL,
  AUCTION_ITEM_SALES_REPORT_DETAILS_URL,
  SALLER_OVERVIEW_REPORT_DOWNLOAD_URL,
} from "../../Constants";
import { fetchRecords, downloadCSV } from "../../services/ReportsService";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Formik } from "formik";
import { Select } from "../../components/Select";
import ZDatePicker from "../../components/form/ZDatePicker";
import moment from "moment";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import Alert from "../../components/UI/Alert";

const SalesReport = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();
  const [values, setValues] = useState([]);

  const fetchReportData = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?" +
        Object.keys(values)
          .map((key) => key + "=" + values[key])
          .join("&");

      const rs = await fetchRecords(
        AUCTION_ITEM_SALES_REPORT_URL + statusParam + pagingParams
      );
      console.log("Sales report",rs);
      setItemList(rs?.sales);

      setTotal(rs.total);

      const pages = Math.ceil((rs.total ?? 0) / (pageSize ?? 1));
      setTotalPages(pages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const downloadReportData = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?" +
        Object.keys(values)
          .map((key) => key + "=" + values[key])
          .join("&");

      const rs = await downloadCSV(
        SALLER_OVERVIEW_REPORT_DOWNLOAD_URL + statusParam + pagingParams
      );
      console.log(rs);
      const url = window.URL.createObjectURL(new Blob([rs]));

      const link = document.createElement("a");
      link.href = url;
      link.download = "saller-overview-report.csv";

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchReportData();
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchReportData();
  }, [values]);

  const filterInitialValues = {
    fromDate: "",
    toDate: "",
    sellerId: "",
  };

  const filterValidationSchema = Yup.object().shape({
    fromDate: Yup.date().nullable().typeError("Invalid date"),
    toDate: Yup.date().nullable().typeError("Invalid date"),
    sellerId: Yup.string(),
  });

  const fetchReportDataByStatus = async (value) => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?status=" + value.status ;

      const rs = await fetchRecords(
        AUCTION_ITEM_SALES_REPORT_DETAILS_URL +
          statusParam +
          pagingParams
      );

      let reportTitle = "Sales Report Detail - ";
      if (value.status === "SUCCESSFUL_ENDED") {
        reportTitle += "Sold";
      } else if (value.status === "UNSUCCESSFUL_ENDED") {
        reportTitle += "Unsold";
      } else if(value.status === "ALL"){
        reportTitle += "Total Auctions";
      }

      navigate("/sales-details", {
        state: {
          data: rs,
          total: rs.totalElements,
          totalPages: rs.totalPages,
          reportTitle: reportTitle,
          status: value.status,
        },
      });
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading
                title="Sales Report"
                headingSize="sm"
              >

                <Link to="/seller-approval-requests" className="font-size-16">
                  Back to Main Menu
                </Link>
              </PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={filterInitialValues}
                    // validationSchema={filterValidationSchema}
                    onSubmit={(values) => {
                      console.log("submitted values", values);

                      const submitted = {
                        ...values,
                        fromDate: values?.fromDate
                          ? moment(new Date(values?.fromDate)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                        toDate: values?.toDate
                          ? moment(new Date(values?.toDate)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                      };

                      const valArr = [];
                      for (const [key, value] of Object.entries(submitted)) {
                        console.log(`${key}: ${value}`);
                        valArr[key] = value;
                      }

                      setValues(valArr);
                    }}
                  >
                    {({
                      handleSubmit,
                      resetForm,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form>
                        <div className="row">
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="Sale Date From"
                                maxDate={new Date()}
                                name="fromDate"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="Sale Date To"
                                name="toDate"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-lg-3">
                            <div
                              className={`js-form-message mb-4 ${tableClasses.buttonsAlignment} `}
                            >
                              <button
                                className="btn btn-secondary px-5 mr-2"
                                type="button"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  resetForm();
                                  setValues([]);
                                }}
                              >
                                Reset
                              </button>
                              <button
                                className="btn btn-primary-dark-w px-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  console.log("errors", res);
                                  if (res && Object.values(res)?.length > 0) {
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Alert
                        type="info"
                        children="Note: Click on Total Auction, Total Sold Items and Total UnSold to view Detail Report"
                      />
                </div>
                <div className="col-12">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <SalesReport.TableBodyRow
                          key={i}
                          totalAuction={item.totalAuction}
                          totalSold={item.totalSold}
                          totalUnsold={item.totalUnsold}
                          totalBuyerFeeCollected={item.totalBuyerFeeCollected}
                          totalAuctionAmount={item.totalAuctionAmount}
                          totalBuyerPremiumCollected={item.totalBuyerPremiumCollected}
                          fetchReportDataByStatus={fetchReportDataByStatus}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <SalesReport.MobileBodyRow
                          key={i}
                          totalAuction={item.totalAuction}
                          totalSold={item.totalSold}
                          totalUnsold={item.totalUnsold}
                          totalBuyerFeeCollected={item.totalBuyerFeeCollected}
                          totalAuctionAmount={item.totalAuctionAmount}
                          totalBuyerPremiumCollected={item.totalBuyerPremiumCollected}
                          fetchReportDataByStatus={fetchReportDataByStatus}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

SalesReport.TableBodyRow = ({
  totalAuction,
  totalSold,
  totalUnsold,
  totalBuyerFeeCollected,
  totalAuctionAmount,
  totalBuyerPremiumCollected,
  fetchReportDataByStatus
}) => {
  return (
    <>
      <tr>
        <td>
          <div className={tableClasses.label}>Total Auction</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "ALL",
                })
              }
            >
              {totalAuction}
            </Link>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Total Sold</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "SUCCESSFUL_ENDED",
                })
              }
            >
              {totalSold}
            </Link>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Total Unsold</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "UNSUCCESSFUL_ENDED",
                })
              }
            >
              {totalUnsold}
            </Link>
          </div>
        </td>
        {/* <td>
          <div className={tableClasses.label}>Premium Collected</div>
          <div className={tableClasses.value}>{formatUSD(totalBuyerPremiumCollected)}</div>
        </td> */}
        <td>
          <div className={tableClasses.label}>Total Premium Collected</div>
          <div className={tableClasses.value}>{formatUSD(totalBuyerFeeCollected)}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Total Auction Amount</div>
          <div className={tableClasses.value}>{formatUSD(totalAuctionAmount)}</div>
        </td>
      </tr>
    </>
  );
};

SalesReport.MobileBodyRow = ({
  totalAuction,
  totalSold,
  totalUnsold,
  totalBuyerFeeCollected,
  totalAuctionAmount,
  totalBuyerPremiumCollected,
  fetchReportDataByStatus
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Auction</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "ALL",
                    })
                  }
                >
                  {totalAuction}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Sold</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "SUCCESSFUL_ENDED"
                    })
                  }
                >
                  {totalSold}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Unsold</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "UNSUCCESSFUL_ENDED"
                    })
                  }
                >
                  {totalUnsold}
                </Link>
              </div>
            </div>
            {/* <div className={tableClasses.item}>
              <div className={tableClasses.label}>Premium Collected</div>
              <div className={tableClasses.value}>{formatUSD(totalBuyerPremiumCollected)}</div>
            </div> */}
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Premium Collected</div>
              <div className={tableClasses.value}>{formatUSD(totalBuyerFeeCollected)}</div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Auction Amount</div>
              <div className={tableClasses.value}>{formatUSD(totalAuctionAmount)}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
