import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import tableClasses from "../../components/table/ListingTable.module.css";
import AccountMenu from "../../components/AccountMenu/AccountMenu";

import { useEffect } from "react";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Field, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Alert } from "@mui/material";
import {
  allAuctionConfig,
  updateAuctionConfig,
} from "../../services/ConfigService";
import {
  allContactUs,
  viewConatct,
} from "../../services/AdmminDashboardServices";
import ListingTable from "../../components/table/ListingTable";

const ContactDetails = () => {
  const { state } = useLocation();
  console.log(state);
  const { id, color } = state;
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [contact, setContact] = useState([]);
  const updateContactView = (contactId) => {
    ctx.showProgress(true);
    viewConatct(contactId)
      .then((res) => {
        ctx.showProgress(false);
        navigate("/contact-us-request");
      })
      .catch((error) => {
        ctx.showProgress(false);
        navigate("/contact-us-request");
      });
  };

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu contactUsActive />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <PlaceholderHeading title="Contact Us Request"></PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>
              <table className="table">
                <tr>
                  <td>
                    <div className={tableClasses.label}>Name</div>
                  </td>
                  <td>
                    <div className={tableClasses.label}>
                      {state.firstName} {state.lastName}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={tableClasses.label}>Email</div>
                  </td>
                  <td>
                    <div className={tableClasses.label}>{state.email}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={tableClasses.label}>Message Type</div>
                  </td>
                  <td>
                    <div className={tableClasses.label}>{state.purpose}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={tableClasses.label}>Message</div>
                  </td>
                  <td>
                    <div className={tableClasses.label}>{state.message}</div>
                  </td>
                </tr>
                {state.viewStatus != "viewed" && (
                  <tr>
                    <td>
                      {" "}
                      <button
                        className="mt-5 btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          updateContactView(state.contactId);
                        }}
                      >
                        Mark Message as Read & Responded
                      </button>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactDetails;
