import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import tableClasses from "../../components/table/ListingTable.module.css";
import AccountMenu from "../../components/AccountMenu/AccountMenu";

import { useEffect } from "react";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Field, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Alert } from "@mui/material";
import {
  allAuctionConfig,
  updateAuctionConfig,
} from "../../services/ConfigService";
import {
  allContactUs,
  viewConatct,
} from "../../services/AdmminDashboardServices";
import ListingTable from "../../components/table/ListingTable";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import DropDownButton from "../../components/button/DropDownButton";
import DropDownItem from "../../components/button/DropDownItem";

const Contact = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [contact, setContact] = useState([]);

  const [filter, setFilter] = useState();

  useEffect(() => {
    ctx.showProgress(true);
    allContactUs("empty")
      .then((res) => {
        setContact(res);
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  }, []);

  const updateContactView = (contactId) => {
    let conatact = contact.find((x) => x.contactId == contactId);

    navigate("/contact-details/", { state: conatact });
    // ctx.showProgress(true);
    // viewConatct(contactId)
    //   .then((res) => {
    //     allContactUs()
    //       .then((res) => {
    //         setContact(res);
    //         ctx.showProgress(false);
    //       })
    //       .catch((error) => {
    //         ctx.showProgress(false);
    //       });
    //   })
    //   .catch((error) => {
    //     ctx.showProgress(false);
    //   });
  };

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu contactUsActive />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <PlaceholderHeading title="Contact Us Request"></PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12">
                  <ButtonsPanel minWidth={`230px`}>
                    <DropDownButton
                      styles={{ minWidth: "200px" }}
                      onItemClicked={(v) => {
                        ctx.showProgress(true);
                        allContactUs(v)
                          .then((res) => {
                            setContact(res);
                            ctx.showProgress(false);
                          })
                          .catch((error) => {
                            ctx.showProgress(false);
                          });
                      }}
                    >
                      <DropDownItem label="New Message" value="empty" />
                      <DropDownItem label="Responded Message" value="viewed" />
                    </DropDownButton>
                  </ButtonsPanel>
                </div>
              </div>

              <ListingTable
                tableBody={contact?.map((item, i) => {
                  return (
                    <Contact.TableBodyRow
                      key={i}
                      data={item}
                      editClicked={(contactId) => {
                        updateContactView(contactId);
                        //navigate("/update-user/" + userId);
                      }}
                    />
                  );
                })}
                mobileBody={contact?.map((item, i) => {
                  return (
                    <Contact.MobileBodyRow
                      key={i}
                      data={item}
                      editClicked={(contactId) => {
                        updateContactView(contactId);
                        //navigate("/update-user/" + userId);
                      }}
                    />
                  );
                })}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

Contact.TableBodyRow = ({ data, editClicked = (userId) => {} }) => {
  return (
    <>
      <tr>
        <td>
          <div className={tableClasses.label}>Name</div>
          <div className={tableClasses.value}>
            {data?.firstName} {data?.lastName}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Email</div>
          <div className={tableClasses.value}>{data?.email}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Purpose</div>
          <div className={tableClasses.value}>
            {data?.purpose.replace(/_/g, " ")}
          </div>
        </td>
        {/* <td>
          <div className={tableClasses.label}>Status</div>
          <div className={tableClasses.value}>
            {data?.viewStatus == "empty" ? "Not Viewed" : data?.viewStatus}
          </div>
        </td> */}
        <td>
          <a
            href="#"
            className="btn btn-block py-1 btn-primary-dark-w"
            onClick={(e) => {
              e.preventDefault();
              editClicked(data?.contactId);
            }}
          >
            View Message
          </a>
        </td>
      </tr>
    </>
  );
};

Contact.MobileBodyRow = ({ data, editClicked = (userId) => {} }) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={`${tableClasses.block} ${tableClasses.buttons}`}>
        <div className={tableClasses.buttonRow}>
          <div className={tableClasses.value}>
            <a
              href="#"
              className={tableClasses.link}
              onClick={(e) => {
                e.preventDefault();
                editClicked(data?.contactId);
              }}
            >
              Mark View
            </a>
          </div>
        </div>
      </div>

      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.heading}>
              {data?.firstName} {data?.lastName}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Email</div>
            <div className={tableClasses.value}>{data?.email}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Purpose</div>
            <div className={tableClasses.value}>
              {data?.purpose.replace(/_/g, " ")}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Status</div>
            <div className={tableClasses.value}>
              {data?.viewStatus == "empty" ? "Not Viewed" : data?.viewStatus}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
