import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import tableClasses from "../../components/table/ListingTable.module.css";
import AccountMenu from "../../components/AccountMenu/AccountMenu";

import { useEffect } from "react";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Field, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Alert } from "@mui/material";
import {
  allAuctionConfig,
  updateAuctionConfig,
} from "../../services/ConfigService";

const Config = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [initialValue, setInitialValue] = useState({});
  const [config, setConfig] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  useEffect(() => {
    ctx.showProgress(true);
    allAuctionConfig()
      .then((res) => {
        let values = {};
        res.forEach((element) => {
          values[element.key] =
            element.value != undefined ? element.value : "0";
        });
        setInitialValue(values);
        setConfig(res);
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  }, []);

  const update = (values) => {
    console.log(Object.entries(values).map(([key, value]) => ({ key, value })));

    ctx.showProgress(true);
    updateAuctionConfig(
      Object.entries(values).map(([key, value]) => ({ key, value }))
    )
      .then((res) => {
        debugger;
        ctx.showProgress(false);
        setError(false);
        setSuccess("User created successfully");
      })
      .catch((error) => {
        let err = JSON.parse(error.message);
        setError(err);
        setSuccess(false);
        ctx.showProgress(false);
      });
  };

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu configActive />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <PlaceholderHeading title="Configuration Management"></PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              {error && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <label className={{ color: "red" }}>
                      <Alert severity="error">
                        {"Unable to update configurations"}
                      </Alert>
                    </label>
                  </div>
                </div>
              )}
              {success && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <label className={{ color: "green" }}>
                      <Alert severity="success">
                        {"Configurations Updated"}
                      </Alert>
                    </label>
                  </div>
                </div>
              )}

              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={initialValue}
                onSubmit={(values) => {
                  update(values);
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  validateForm,
                  setFieldValue,
                }) => (
                  <form id="passportInfoForm">
                    <div className="row mt-5">
                      <div className="offset-lg-2 col-lg-8 col-md-8">
                        {config.map((item) => {
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="js-form-message mb-4">
                                  <Field
                                    className="form-control"
                                    type="number"
                                    name={item.key}
                                    required="true"
                                    component={ZInputComponent}
                                    placeholder={`Enter ${item.key}`}
                                    label={item.key.replace(/_/g, " ")}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="row mb-12 mt-5">
                      <div className="offset-lg-2 col-lg-8 col-md-8">
                        <Link
                          className="btn-block btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            handleSubmit(e);
                          }}
                        >
                          Update Configurations
                        </Link>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Config;
