import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  SALLER_OVERVIEW_REPORT_URL,
  SALLER_OVERVIEW_REPORT_DETAILS_BY_STATUS_URL,
  SALLER_OVERVIEW_REPORT_DOWNLOAD_URL,
  BASE_URL_FRONTEND_URL
} from "../../Constants";
import { fetchRecords, downloadCSV } from "../../services/ReportsService";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Formik } from "formik";
import { Select } from "../../components/Select";
import ZDatePicker from "../../components/form/ZDatePicker";
import Label from "../../components/UI/Label";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import moment from "moment";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";

const SellerOverviewReportDetail = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  console.log(location.state);

  const [itemList, setItemList] = useState(location.state.data.content);

  const [reportTitle, setReportTitle] = useState(location.state.reportTitle);
  const [total, setTotal] = useState(location.state.total);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState(location.state.totalPages);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading title={reportTitle} headingSize="sm">
                <Link to="/seller-activity-report" className="font-size-16">
                  Back to Main Report
                </Link>
              </PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12 mt-3">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <SellerOverviewReportDetail.TableBodyRow
                          key={i}
                          auctionID={item.auctionID}
                          auctionStatus={item.auctionStatus}
                          auctionType={item.auctionType}
                          currency={item.currency}
                          currentBid={item.currentBid}
                          endDate={item.endDate}
                          highestBidderContactnumber={
                            item.highestBidderContactnumber
                          }
                          highestBidderEmailAddress={
                            item.highestBidderEmailAddress
                          }
                          highestBidderId={item.highestBidderId}
                          highestBidderName={item.highestBidderName}
                          itemImage={item.itemImage}
                          itemName={item.itemName}
                          numberOfBids={item.numberOfBids}
                          paymentStatus={item.paymentStatus}
                          phoneType={item.phoneType}
                          reservePrice={item.reservePrice}
                          startDate={item.startDate}
                          startingPrice={item.startingPrice}
                          pickupDate={item.pickupDate}
                          paymentDate={item.paymentDate}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <SellerOverviewReportDetail.MobileBodyRow
                          key={i}
                          auctionID={item.auctionID}
                          auctionStatus={item.auctionStatus}
                          auctionType={item.auctionType}
                          currency={item.currency}
                          currentBid={item.currentBid}
                          endDate={item.endDate}
                          highestBidderContactnumber={
                            item.highestBidderContactnumber
                          }
                          highestBidderEmailAddress={
                            item.highestBidderEmailAddress
                          }
                          highestBidderId={item.highestBidderId}
                          highestBidderName={item.highestBidderName}
                          itemImage={item.itemImage}
                          itemName={item.itemName}
                          numberOfBids={item.numberOfBids}
                          paymentStatus={item.paymentStatus}
                          phoneType={item.phoneType}
                          reservePrice={item.reservePrice}
                          startDate={item.startDate}
                          startingPrice={item.startingPrice}
                          pickupDate={item.pickupDate}
                          paymentDate={item.paymentDate}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

SellerOverviewReportDetail.TableBodyRow = ({
  auctionID,
  auctionStatus,
  auctionType,
  currency,
  currentBid,
  endDate,
  highestBidderContactnumber,
  highestBidderEmailAddress,
  highestBidderId,
  highestBidderName,
  itemImage,
  itemName,
  numberOfBids,
  paymentStatus,
  phoneType,
  reservePrice,
  startDate,
  startingPrice,
  pickupDate,
  paymentDate,
}) => {
  return (
    <>
      <tr>
        <td style={{ width: "12%" }}>
          <div className="mt-2">
            <img
              className="w-90"
              style={{ height: "110px" }}
              src={itemImage}
              alt="auction item image"
            />
          </div>
        </td>
        <td style={{ width: "20%" }}>
          <div className={`${tableClasses.label}`}>
            <span className="text-uppercase font-weight-bold">{auctionID}</span>{" "}
            |{" "}
            <span className="text-capitalize">
              {auctionStatus.replaceAll("_", " ")}
            </span>
          </div>
          <div className={tableClasses.value}>
            <Link to={BASE_URL_FRONTEND_URL+"/auction/"+auctionID} target="_blank">{itemName}</Link>
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Current Bid: </span>
            <span className={tableClasses.value}> {formatUSD(currentBid)}</span>
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Auction Type: </span>
            <span className={tableClasses.value}> {auctionType}</span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Number of Bids</div>
          <div className={tableClasses.value}>{numberOfBids}</div>
          <div className={tableClasses.label}>Payment Status</div>
          <div className={tableClasses.value}>
            {paymentStatus.replaceAll("_", " ")}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Starting Price</div>
          <div className={tableClasses.value}>
            {formatUSD(startingPrice)}
          </div>
          <div className={tableClasses.label}>Reserve Price</div>
          <div className={tableClasses.value}>
            {formatUSD(reservePrice)}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Start Date</div>
          <div className={tableClasses.value}>{startDate} </div>
          <div className={tableClasses.label}>End Date</div>
          <div className={tableClasses.value}>{endDate}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Higest Bidder ID | Name</div>
          <div className={tableClasses.value}>
            {highestBidderId ? highestBidderId : "n/a"} {" | "}{" "}
            {highestBidderName ? highestBidderName : "n/a"}
          </div>
          <div className={tableClasses.label}>Higest Bidder Email</div>
          <div className={tableClasses.value}>
            {highestBidderEmailAddress ? highestBidderEmailAddress : "n/a"}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Higest Bidder Contact Type</div>
          <div className={tableClasses.value}>
            {phoneType ? phoneType : "n/a"}{" "}
          </div>
          <div className={tableClasses.label}>Higest Bidder Contact Number</div>
          <div className={tableClasses.value}>
            {highestBidderContactnumber ? highestBidderContactnumber : "n/a"}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Pickup Date</div>
          <div className={tableClasses.value}>{pickupDate}</div>
          <div className={tableClasses.label}>Payment Date</div>
          <div className={tableClasses.value}>{paymentDate}</div>
        </td>
      </tr>
    </>
  );
};

SellerOverviewReportDetail.MobileBodyRow = ({
  auctionID,
  auctionStatus,
  auctionType,
  currency,
  currentBid,
  endDate,
  highestBidderContactnumber,
  highestBidderEmailAddress,
  highestBidderId,
  highestBidderName,
  itemImage,
  itemName,
  numberOfBids,
  paymentStatus,
  phoneType,
  reservePrice,
  startDate,
  startingPrice,
  pickupDate,
  paymentDate,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={`${tableClasses.blockText} w-100`}>
          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <img
                className="w-100"
                style={{ height: "225px" }}
                src={itemImage}
                alt="auction item image"
              />
            </div>
          </div>
          <div className={`${tableClasses.label} mt-2`}>
            <span className="text-uppercase font-weight-bold">{auctionID}</span>{" "}
            |{" "}
            <span className="text-capitalize">
              {auctionStatus.replaceAll("_", " ")}
            </span>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Item Name</div>
            <div className={tableClasses.value}>
            <Link to={BASE_URL_FRONTEND_URL+"/auction/"+auctionID} target="_blank">{itemName}</Link>
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Current Bid: </div>
            <div className={tableClasses.value}> {formatUSD(currentBid)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Auction Type: </div>
            <div className={tableClasses.value}> {auctionType}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Number of Bids</div>
            <div className={tableClasses.value}>{numberOfBids}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Status</div>
            <div className={tableClasses.value}>
              {paymentStatus.replaceAll("_", " ")}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Starting Price</div>
            <div className={tableClasses.value}>
              {formatUSD(startingPrice)}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Reserve Price</div>
            <div className={tableClasses.value}>
              {formatUSD(reservePrice)}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Start Date</div>
            <div className={tableClasses.value}>{startDate} </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>End Date</div>
            <div className={tableClasses.value}>{endDate}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Higest Bidder ID | Name</div>
            <div className={tableClasses.value}>
              {highestBidderId ? highestBidderId : "n/a"} {" | "}{" "}
              {highestBidderName ? highestBidderName : "n/a"}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Higest Bidder Email</div>
            <div className={tableClasses.value}>
              {highestBidderEmailAddress ? highestBidderEmailAddress : "n/a"}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Higest Bidder Contact Type</div>
            <div className={tableClasses.value}>
              {phoneType ? phoneType : "n/a"}{" "}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>
              Higest Bidder Contact Number
            </div>
            <div className={tableClasses.value}>
              {highestBidderContactnumber ? highestBidderContactnumber : "n/a"}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Pickup Date</div>
            <div className={tableClasses.value}>{pickupDate}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Date</div>
            <div className={tableClasses.value}>{paymentDate}</div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SellerOverviewReportDetail;
