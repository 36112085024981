import React from "react";
import DatePicker from "react-datepicker";
import ErrorMessage from "./ErrorMessage";

const ZInputComponent = ({
  field,
  label,
  highlightedLabel = false,
  value,
  required,
  disabled,
  placeholder,
  form: { touched, errors, isValid },
  type = "text",
  error = "",
  serverError,
  className = "form-control",
  ...props
}) => {
  let fieldError = errors[field.name];
  if (field?.name?.indexOf(".") !== -1) {
    const names = field.name?.split(".");
    if (names?.length > 1 && errors[names[0]]) {
      fieldError = errors[names[0]][names[1]];
    }
  }

  let fieldTouch = touched[field.name];
  if (field?.name?.indexOf(".") !== -1) {
    const names = field.name?.split(".");
    if (names?.length > 1 && touched[names[0]]) {
      fieldTouch = touched[names[0]][names[1]];
    }
  }

  let fieldValue = "";
  if (field.value) {
    fieldValue = field.value;
  } else if (value) {
    fieldValue = value;
  }

  return (
    <>
      {label && (
        <label
          className={`form-label ${highlightedLabel ? "highlighted" : ""}`}
        >
          {label} {required && <span className="color-red">*</span>}
        </label>
      )}
      {(type == "number" || type == "text" || type == "date" || type == "email") && (
        <input
          type={type}
          {...field}
          {...props}
          className={className}
          placeholder={placeholder}
          value={fieldValue}
          disabled={disabled ?? false}
        />
      )}
      {type == "textarea" && (
        <textarea
          {...field}
          {...props}
          className={className}
          placeholder={placeholder}
          disabled={disabled ?? false}
        >
          {fieldValue}
        </textarea>
      )}

      {(fieldError || fieldTouch || serverError) && (
        <ErrorMessage>{fieldError || serverError}</ErrorMessage>
      )}
    </>
  );
};

export default ZInputComponent;
