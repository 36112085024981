import React from "react";
import classes from "./dashboard.module.css";

const ListingInfoBlock = ({ label, value }) => {
  return (
    <div className={classes.listingInfoBlock}>
      <span className={`col-9 ${classes.label}`}>{label}</span>
      <span className={`col-3 text-center ${classes.value}`}>{value}</span>
    </div>
  );
};

export default ListingInfoBlock;
