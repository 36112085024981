import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./ScheduleDemo.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import LotsTable from "../../components/table/LotsTable";
import { isStopFlowConditionMet } from "../../services/Utilities";
import { SELLER_DEMO_DETAIL_URL } from "../../Constants";
import { fetchRecords } from "../../services/SellerService";
import ViewField from "../../components/UI/ViewField";

const DemoDetail = ({ demoId }) => {
  const ctx = useAuthContext();
  const navigate = useNavigate();

  const [detail, setDetail] = useState({});

  const fetchData = async (requestId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchRecords(
        SELLER_DEMO_DETAIL_URL.replace("{demoId}", requestId)
      );
      console.log("item", rs);
      setDetail(rs);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    if (demoId) fetchData(demoId);
  }, [demoId]);

  const tableHeader = (
    <tr>
      <th></th>
      <th>Remarks</th>
      <th>Decision</th>
      <th>Submitted On</th>
      <th>Approval By</th>
    </tr>
  );

  return (
    <>
      <h2 className="h4 font-weight-bold">Demo Request Details</h2>

      <div className="mb-5">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className={`row ${classes.topRow}`}>
        <div className="col">
          <h4>
            {detail?.dateForMeeting} {detail?.timeForMeeting} for{" "}
            {detail?.durationOfMeeting} minutes
          </h4>

          <div className="row">
            <div className="col-12 my-2">
              <ViewField label="Remarks" value={detail?.remarks} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-12 my-2">
              <ViewField label="Applied On" value={detail?.createdOn} />
            </div>
          </div>

          {detail?.guests && Object.keys(detail?.guests).length > 0 && (
            <>
              <h5 className="mt-5">Demo Guests</h5>
              {detail?.guests.map((guest, i) => {
                return (
                  <div className="row">
                    <div className="col-md-6 col-12 my-2">
                      <ViewField label="Name" value={guest?.name} />
                    </div>
                    <div className="col-md-6 col-12 my-2">
                      <ViewField label="Email" value={guest?.email} />
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>

      {detail?.approvalHistory && (
        <>
          <div className="row">
            <div className="col mt-5 mb-5">
              <h5>Approval History</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12 my-2">
              <ViewField
                label="Approval By"
                value={detail?.approvalHistory?.approvedBy}
              />
            </div>
            <div className="col-md-6 col-12 my-2">
              <ViewField
                label="Approval On"
                value={detail?.approvalHistory?.createdOn}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 my-2">
              <ViewField
                label="Video Conference Link"
                value={detail?.approvalHistory?.videoLink}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 my-2">
              <ViewField
                label="Remarks"
                value={detail?.approvalHistory?.remarks}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DemoDetail;
