import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import DemoDetail from "./DemoDetail";

const ViewDemoDetails = () => {
  const params = useParams();

  const [demoId, setDemoId] = useState();

  useEffect(() => {
    if (params.requestId) setDemoId(params.requestId);
  }, [params.requestId]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                sellerManagementActive
                subMenu={"seller-demo-requests"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              {demoId && <DemoDetail demoId={demoId} />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ViewDemoDetails;
