import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import classes from "./TopBarMyAccountMenu.module.css";
import profileIcon from "../assets/images/profileIcon.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { isSeller } from "../services/Utilities";

const TopBarMyAccountMenu = () => {
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const documentClickEventHandler = (e) => {
    if (!menuRef?.current?.contains(e.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", documentClickEventHandler);

    return () => {
      window.removeEventListener("click", documentClickEventHandler);
    };
  }, []);

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <>
      <li
        ref={menuRef}
        className={`list-inline-item mr-0 u-header-topbar__nav-item ${classes.dropdown}`}
      >
        <div
          className={classes.span}
          onClick={(e) => {
            setShowMenu((show) => !show);
          }}
        >
          <span className={classes.icon}>
            <img src={profileIcon} />
          </span>
          <span className={classes.text}>
            Welcome, <span className={classes.name}>{user.name}</span>
          </span>

          <span className={classes.caret}>
            <i className="fa fa-caret-down"></i>
          </span>
        </div>

        <ul
          className={`${classes.subMenu} ${showMenu ? classes.show : ""}`}
          onMouseLeave={(e) => {
            setShowMenu(false);
          }}
        >
          <li>
            <Link to="/">My Dashboard</Link>
          </li>

          <li className={classes.noPadding}>
            <hr />
          </li>
          <li>
            <Link to="/logout">Sign Out</Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default TopBarMyAccountMenu;
