import {
  REGISTERED_SELLER_FEE_UPDATE_URL,
  SELLER_APPROVAL_DECISION_URL,
} from "../Constants";
import authenticatedFetch from "./fetch";

export async function fetchRecords(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function sellerApproval(requestBody) {
  const response = await authenticatedFetch(`${SELLER_APPROVAL_DECISION_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function sellerLoginStatusUpdate(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function buyerFeeUpdate(userId, requestBody) {
  const response = await authenticatedFetch(
    `${REGISTERED_SELLER_FEE_UPDATE_URL.replace("{sellerId}", userId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}
