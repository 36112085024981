import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  SALLER_OVERVIEW_REPORT_URL,
  SALLER_OVERVIEW_REPORT_DETAILS_BY_STATUS_URL,
  SALLER_OVERVIEW_REPORT_DOWNLOAD_URL,
} from "../../Constants";
import { fetchRecords, downloadCSV } from "../../services/ReportsService";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Formik } from "formik";
import { Select } from "../../components/Select";
import ZDatePicker from "../../components/form/ZDatePicker";
import moment from "moment";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import Alert from "../../components/UI/Alert";

const SellerOverviewReport = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();
  const [values, setValues] = useState([]);

  const fetchReportData = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?" +
        Object.keys(values)
          .map((key) => key + "=" + values[key])
          .join("&");

      const rs = await fetchRecords(
        SALLER_OVERVIEW_REPORT_URL + statusParam + pagingParams
      );
      console.log(rs);
      setItemList(rs?.data);

      setTotal(rs.total);

      const pages = Math.ceil((rs.total ?? 0) / (pageSize ?? 1));
      setTotalPages(pages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const downloadReportData = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?" +
        Object.keys(values)
          .map((key) => key + "=" + values[key])
          .join("&");

      const rs = await downloadCSV(
        SALLER_OVERVIEW_REPORT_DOWNLOAD_URL + statusParam + pagingParams
      );
      console.log(rs);
      const url = window.URL.createObjectURL(new Blob([rs]));

      const link = document.createElement("a");
      link.href = url;
      link.download = "saller-overview-report.csv";

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchReportData();
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchReportData();
  }, [values]);

  const filterInitialValues = {
    fromDate: "",
    toDate: "",
    sellerId: "",
  };

  const filterValidationSchema = Yup.object().shape({
    fromDate: Yup.date().nullable().typeError("Invalid date"),
    toDate: Yup.date().nullable().typeError("Invalid date"),
    sellerId: Yup.string(),
  });

  const fetchReportDataByStatus = async (value) => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?status=" + value.status + "&sellerId=" + value.sellerId;

      const rs = await fetchRecords(
        SALLER_OVERVIEW_REPORT_DETAILS_BY_STATUS_URL +
          statusParam +
          pagingParams
      );

      let reportTitle = "Seller Activity Report Detail - ";
      if (value.status === "ACTIVE") {
        reportTitle += "Active";
      } else if (value.status === "SUCCESSFUL_ENDED") {
        reportTitle += "Sold";
      } else if (value.status === "UNSUCCESSFUL_ENDED") {
        reportTitle += "Unsold";
      } else if (value.status === "INVOICE_GENERATED") {
        reportTitle += "Awaiting Bidder Payment";
      } else if (value.status === "WAITING_TO_RECEIVE_PAYMENT") {
        reportTitle += "Awaiting Payment Acknowledgment";
      } else if (value.status === "WAITING_FOR_ITEM_PICKUP") {
        reportTitle += "Awaiting Pickup";
      }else{
        reportTitle += "Total Listings";
      }

      navigate("/seller-activity-details", {
        state: {
          data: rs,
          total: rs.totalElements,
          totalPages: rs.totalPages,
          reportTitle: reportTitle,
        },
      });
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading
                title="Seller Activity Report"
                headingSize="sm"
              >
                <Link
                  onClick={() => downloadReportData()}
                  className="font-size-16"
                >
                  Download CSV
                </Link>{" "}
                {" | "}
                <Link to="/seller-approval-requests" className="font-size-16">
                  Back to Main Menu
                </Link>
              </PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={filterInitialValues}
                    // validationSchema={filterValidationSchema}
                    onSubmit={(values) => {
                      console.log("submitted values", values);

                      const submitted = {
                        ...values,
                        fromDate: values?.fromDate
                          ? moment(new Date(values?.fromDate)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                        toDate: values?.toDate
                          ? moment(new Date(values?.toDate)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                      };

                      const valArr = [];
                      for (const [key, value] of Object.entries(submitted)) {
                        console.log(`${key}: ${value}`);
                        valArr[key] = value;
                      }

                      setValues(valArr);
                    }}
                  >
                    {({
                      handleSubmit,
                      resetForm,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form>
                        <div className="row">
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="sellerId"
                                component={ZInputComponent}
                                placeholder="Enter Seller ID"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              {/* <Label>From Date</Label> */}
                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="From"
                                maxDate={new Date()}
                                name="fromDate"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="To"
                                name="toDate"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-lg-3">
                            <div
                              className={`js-form-message mb-4 ${tableClasses.buttonsAlignment} `}
                            >
                              <button
                                className="btn btn-secondary px-5 mr-2"
                                type="button"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  resetForm();
                                  setValues([]);
                                }}
                              >
                                Reset
                              </button>
                              <button
                                className="btn btn-primary-dark-w px-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  console.log("errors", res);
                                  if (res && Object.values(res)?.length > 0) {
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Alert
                    type="info"
                    children="Note: Click on Total Awaiting Bidder Payment, Total ,
                        Payment Acknowledgment, Total Sold Items, Total UnSold
                        items, Total Awaiting Pickup to view Detail Report"
                  />
                </div>
                <div className="col-12">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <SellerOverviewReport.TableBodyRow
                          key={i}
                          sellerId={item.sellerId}
                          fullName={item.fullName}
                          email={item.email}
                          contactNumber={item.contactNumber}
                          phoneType={item.phoneType}
                          registrationDate={item.registrationDate}
                          totalActive={item.totalActive}
                          totalAuction={item.totalAuction}
                          totalAwaitingBidderPayment={
                            item.totalAwaitingBidderPayment
                          }
                          totalAwaitingPaymentAck={item.totalAwaitingPaymentAck}
                          totalAwaitingPickup={item.totalAwaitingPickup}
                          totalSold={item.totalSold}
                          totalUnsold={item.totalUnsold}
                          totalSalesRevenue={item.totalSalesRevenue}
                          fetchReportDataByStatus={fetchReportDataByStatus}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <SellerOverviewReport.MobileBodyRow
                          key={i}
                          sellerId={item.sellerId}
                          fullName={item.fullName}
                          email={item.email}
                          contactNumber={item.contactNumber}
                          phoneType={item.phoneType}
                          registrationDate={item.registrationDate}
                          totalActive={item.totalActive}
                          totalAuction={item.totalAuction}
                          totalAwaitingBidderPayment={
                            item.totalAwaitingBidderPayment
                          }
                          totalAwaitingPaymentAck={item.totalAwaitingPaymentAck}
                          totalAwaitingPickup={item.totalAwaitingPickup}
                          totalSold={item.totalSold}
                          totalUnsold={item.totalUnsold}
                          totalSalesRevenue={item.totalSalesRevenue}
                          fetchReportDataByStatus={fetchReportDataByStatus}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

SellerOverviewReport.TableBodyRow = ({
  sellerId,
  fullName,
  email,
  contactNumber,
  phoneType,
  registrationDate,
  totalActive,
  totalAuction,
  totalAwaitingBidderPayment,
  totalAwaitingPaymentAck,
  totalAwaitingPickup,
  totalSold,
  totalUnsold,
  totalSalesRevenue,
  fetchReportDataByStatus,
}) => {
  return (
    <>
      <tr>
        <td>
          <div className={`${tableClasses.label}`}>
            <span className="text-uppercase font-weight-bold">{sellerId}</span>{" "}
            | <span className="text-capitalize">{fullName}</span>
          </div>
          <div className={tableClasses.value}>
            <Link to={"/seller-activity-report/"+sellerId}>{email}</Link>
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>{phoneType} </span> |{" "}
            <span className={tableClasses.value}> {contactNumber}</span>
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Reg Date: </span>
            <span className={tableClasses.value}> {registrationDate}</span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Total Active</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "ACTIVE",
                  sellerId: sellerId,
                })
              }
            >
              {totalActive}
            </Link>
          </div>
          <div className={tableClasses.label}>Total Listings</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "TOTAL_AUCTIONS",
                  sellerId: sellerId,
                })
              }
            >
              {totalAuction}
            </Link>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>
            Total Awaiting Bidder Payment
          </div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "INVOICE_GENERATED",
                  sellerId: sellerId,
                })
              }
            >
              {totalAwaitingBidderPayment}
            </Link>
          </div>
          <div className={tableClasses.label}>
            Total Awaiting Payment Acknowledgment
          </div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "WAITING_TO_RECEIVE_PAYMENT",
                  sellerId: sellerId,
                })
              }
            >
              {totalAwaitingPaymentAck}
            </Link>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Total Sold</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "SUCCESSFUL_ENDED",
                  sellerId: sellerId,
                })
              }
            >
              {totalSold}
            </Link>
          </div>
          <div className={tableClasses.label}>Total Unsold</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "UNSUCCESSFUL_ENDED",
                  sellerId: sellerId,
                })
              }
            >
              {totalUnsold}
            </Link>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Total Awaiting Pickup</div>
          <div className={tableClasses.value}>
            <Link
              onClick={(e) =>
                fetchReportDataByStatus({
                  status: "WAITING_FOR_ITEM_PICKUP",
                  sellerId: sellerId,
                })
              }
            >
              {totalAwaitingPickup}
            </Link>
          </div>
          <div className={tableClasses.label}>Total Sales Revenue</div>
          <div className={tableClasses.value}>
            {formatUSD(totalSalesRevenue)}
          </div>
        </td>
      </tr>
    </>
  );
};

SellerOverviewReport.MobileBodyRow = ({
  sellerId,
  fullName,
  email,
  contactNumber,
  phoneType,
  registrationDate,
  totalActive,
  totalAuction,
  totalAwaitingBidderPayment,
  totalAwaitingPaymentAck,
  totalAwaitingPickup,
  totalSold,
  totalUnsold,
  totalSalesRevenue,
  fetchReportDataByStatus,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <div className={`${tableClasses.label}`}>
                <span className="text-uppercase font-weight-bold">
                  {sellerId}
                </span>{" "}
                | <span className="text-capitalize">{fullName}</span>
              </div>

              <div className={tableClasses.label}>
                <span className={tableClasses.label}>{phoneType} </span> |{" "}
                <span className={tableClasses.value}> {contactNumber}</span>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Email</div>
              <div className={tableClasses.value}>{email}</div>
            </div>

            <div className={tableClasses.item}>
              <span className={tableClasses.label}>Reg Date: </span>
              <span className={tableClasses.value}> {registrationDate}</span>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Active</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "ACTIVE",
                      sellerId: sellerId,
                    })
                  }
                >
                  {totalActive}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Auction</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "TOTAL_AUCTIONS",
                      sellerId: sellerId,
                    })
                  }
                >
                  {totalAuction}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>
                Total Awaiting Bidder Payment
              </div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "INVOICE_GENERATED",
                      sellerId: sellerId,
                    })
                  }
                >
                  {totalAwaitingBidderPayment}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>
                Total Awaiting Payment Acknowledgment
              </div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "WAITING_TO_RECEIVE_PAYMENT",
                      sellerId: sellerId,
                    })
                  }
                >
                  {totalAwaitingPaymentAck}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Sold</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "SUCCESSFUL_ENDED",
                      sellerId: sellerId,
                    })
                  }
                >
                  {totalSold}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Unsold</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "UNSUCCESSFUL_ENDED",
                      sellerId: sellerId,
                    })
                  }
                >
                  {totalUnsold}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Awaiting Pickup</div>
              <div className={tableClasses.value}>
                <Link
                  onClick={(e) =>
                    fetchReportDataByStatus({
                      status: "WAITING_FOR_ITEM_PICKUP",
                      sellerId: sellerId,
                    })
                  }
                >
                  {totalAwaitingPickup}
                </Link>
              </div>
            </div>

            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Total Sales Revenue</div>
              <div className={tableClasses.value}>
                {formatUSD(totalSalesRevenue)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerOverviewReport;
