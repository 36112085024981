import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Verification.module.css";
import {
  deflateErrors,
  formatUSD,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Label from "../../components/UI/Label";
import { IDENTITY_VERIFICATION_DETAIL_URL } from "../../Constants";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";
import {
  fetchRecords,
  identityVerificationApproval,
} from "../../services/IdentityVerificationService";
import ErrorMessage from "../../components/UI/ErrorMessage";
import ViewField from "../../components/UI/ViewField";
import DownloadFileField from "../../components/UI/DownloadFileField";

const VerificationDecision = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [detail, setDetail] = useState({});

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showRejectConfirmBox, setShowRejectConfirmBox] = useState(false);

  const [remarksValue, setRemarksValue] = useState();

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();

  const [dateOfBirth, setDateOfBirth] = useState();
  const [verificationDocuments, setVerificationDocuments] = useState([]);

  const fetchData = async (userId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchRecords(
        IDENTITY_VERIFICATION_DETAIL_URL.replace("{userId}", userId)
      );
      console.log("item", rs);
      setDetail(rs);

      setDateOfBirth([...rs?.verificationDocuments]?.shift()?.dateOfBirth);

      setVerificationDocuments(rs?.verificationDocuments);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const approveAccount = async (userId) => {
    ctx.showProgress(true);
    await approvalDecision({
      userId: userId,
      remarks: remarksValue,
      decision: "VERIFIED",
    });
  };

  const rejectAccount = async (userId) => {
    ctx.showProgress(true);
    await approvalDecision({
      userId: userId,
      remarks: remarksValue,
      decision: "NOT_VERIFIED",
    });
  };

  const approvalDecision = async (requestBody) => {
    identityVerificationApproval(requestBody)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/identity-verification-requests");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;

        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        const json = JSON.parse(e.message);
        if (json.status !== "BAD_REQUEST") {
          return;
        }

        if (json.data.status === "VALIDATION_FAILED") {
          setServerErrors({
            ...deflateErrors(json.data.errors),
          });
        }
      });
  };

  const validateForm = () => {
    if (!remarksValue || remarksValue.trim() == "") {
      setServerErrors({ remarks: "Please enter remarks" });

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (params.userId) fetchData(params.userId);
  }, [params.userId]);

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to verify identity documents?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            approveAccount(detail?.userId);
          }}
        />
      )}

      {showRejectConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to reject identity documents verification?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
            rejectAccount(detail?.userId);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu IdentityVerificationActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Account Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col">
                  <h4>
                    {detail?.firstName} {detail?.lastName}
                  </h4>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Email Address" value={detail?.email} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Applied On" value={detail?.createdOn} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Source"
                        value={detail?.creationSource}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-12 my-2">
                      <ViewField
                        label="Phone Type"
                        value={detail?.bidder?.phoneType}
                      />
                    </div>
                    <div className="col-md-6 col-12 my-2">
                      <ViewField
                        label="Primary Phone Number"
                        value={detail?.bidder?.primaryPhoneNumber}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-12 my-2">
                      <ViewField
                        label="Alternate Phone Type"
                        value={detail?.bidder?.alternatePhoneType}
                      />
                    </div>
                    <div className="col-md-6 col-12 my-2">
                      <ViewField
                        label="Alternate Phone Number"
                        value={detail?.bidder?.alternatePhoneNumber}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8 col-12 my-2">
                      <ViewField
                        label="Street"
                        value={`${detail?.bidder?.addressLineOne} ${detail?.bidder?.addressLineTwo}`}
                      />
                    </div>

                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Zip Code"
                        value={detail?.bidder?.zipCode}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="City"
                        value={detail?.bidder?.city?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="State"
                        value={detail?.bidder?.state?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Country"
                        value={detail?.bidder?.country?.value}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col">
                  <h4>Identity Document</h4>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 col-12 my-2">
                  <ViewField label="Date of birth" value={dateOfBirth} />
                </div>

                {verificationDocuments?.map((d) => {
                  return (
                    <>
                      <div className="col-md-4 col-12 my-2">
                        <ViewField
                          label="Document Type"
                          value={d.documentType}
                        />
                      </div>

                      <div className="col-md-4 col-12 my-2">
                        <ViewField
                          label="Document Number"
                          value={d.documentNumber}
                        />
                      </div>

                      <div className="col-md-4 col-12 my-2">
                        <DownloadFileField
                          label={"Document"}
                          value={"Download"}
                          link={d.filePath}
                        />
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="mt-5">
                <div className="js-form-message">
                  <Label hightlighted>Remarks</Label>
                  <textarea
                    className="form-control"
                    name="remarks"
                    placeholder="Enter approval remarks"
                    onChange={(e) => {
                      setRemarksValue(e?.target?.value);
                    }}
                  ></textarea>
                </div>

                {serverErrors?.remarks && (
                  <ErrorMessage>{serverErrors?.remarks}</ErrorMessage>
                )}
              </div>

              <div className="mt-5">
                <ButtonsPanel>
                  <a
                    href="#"
                    className={`btn btn-secondary ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!validateForm()) {
                        return;
                      }

                      setShowRejectConfirmBox(true);
                    }}
                  >
                    REJECT
                  </a>

                  <a
                    href="#"
                    className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmBox(true);
                    }}
                  >
                    APPROVE
                  </a>
                </ButtonsPanel>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default VerificationDecision;
