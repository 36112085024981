import { SERVICE_URL } from "../Constants";
import store from "../redux/store";
import { changeRoute } from "./Utilities";
import { actionTypes } from "../pages/_redux/authRedux";

const refreshTokenRequest = async (requestBody) => {
  const response = await simpleFetch(`${SERVICE_URL}auth/refresh`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

const authenticatedFetch = async (
  url,
  data = {},
  handleServerErrors = true,
  tryRefreshToken = true
) => {
  const {
    auth: { token, refreshToken },
  } = store.getState();

  let response = await fetch(url, {
    ...data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Handle only 401 here...
  // UN_AUTHORIZED
  if (response.status === 401) {
    if (tryRefreshToken) {
      try {
        let res = await refreshTokenRequest({ refreshToken: refreshToken });
        store.dispatch({
          type: actionTypes.Login,
          payload: {
            token: res.accessToken,
            refreshToken: res.refreshToken,
            user: {
              name: res.fullName,
              username: res.userName,
              type: res.userType,
            },
          },
        });

        // Calling again
        if (res.accessToken) {
          response = await fetch(url, {
            ...data,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${res.accessToken}`,
            },
          });

          return response;
        }
      } catch (e) {
        if (handleServerErrors) {
          store.dispatch({
            type: actionTypes.SetViewExpired,
            payload: { isExpired: true },
          });

          changeRoute("/");
          let errorStatus = { status: "SESSION_EXPIRED" };
          throw new Error(JSON.stringify(errorStatus));
          // return;
        }
      }
    } else {
      if (handleServerErrors) {
        store.dispatch({
          type: actionTypes.SetViewExpired,
          payload: { isExpired: true },
        });

        changeRoute("/");
        let errorStatus = { status: "SESSION_EXPIRED" };
        throw new Error(JSON.stringify(errorStatus));
        // return;
      }
    }
  }

  if (handleServerErrors && response.status >= 500) {
    changeRoute("/error");

    let errorStatus = { status: "ERROR" };
    throw new Error(JSON.stringify(errorStatus));
    // return;
  }

  if (response.status === 429 || response.status === 413) {
    store.dispatch({
      type: actionTypes.SetViewExpired,
      payload: { isExpired: true },
    });

    changeRoute("/too-many-requests");

    let errorStatus = { status: "CYBER_ATTACK" };
    throw new Error(JSON.stringify(errorStatus));
    // return;
  }

  if (handleServerErrors && response.status === 403) {
    changeRoute("/not-authorized");

    let errorStatus = { status: "FORBIDDEN" };
    throw new Error(JSON.stringify(errorStatus));
    // return;
  }

  if (response.status === 400) {
    let errorMessage = { status: "BAD_REQUEST" };
    let resp = null;
    try {
      resp = await response.json();
    } catch (e) {
      resp = e;
    }

    errorMessage = { ...errorMessage, data: resp };
    throw new Error(JSON.stringify(errorMessage));
  }

  // Need to send response, NOT response.json
  return response;
};

const fetchWithFormData = async (url, data = {}, handleServerErrors = true) => {
  const {
    auth: { token, refreshToken },
  } = store.getState();

  let response = await fetch(url, {
    ...data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Handle only 401 here...
  // UN_AUTHORIZED
  if (response.status === 401) {
    try {
      let res = await refreshTokenRequest({ refreshToken: refreshToken });
      store.dispatch({
        type: actionTypes.Login,
        payload: {
          token: res.token,
          refreshToken: res.refreshToken,
        },
      });

      // Calling again
      if (res.token) {
        response = await fetch(url, {
          ...data,
          headers: {
            Authorization: `Bearer ${res.token}`,
          },
        });

        return response;
      }
    } catch (e) {
      store.dispatch({
        type: actionTypes.SetViewExpired,
        payload: { isExpired: true },
      });
      // throw e;
      let errorStatus = { status: "SESSION_EXPIRED" };
      throw new Error(JSON.stringify(errorStatus));
    }
  }

  if (handleServerErrors && response.status >= 500) {
    changeRoute("/error");
    // return;
    let errorStatus = { status: "ERROR" };
    throw new Error(JSON.stringify(errorStatus));
  }

  if (response.status === 429 || response.status === 413) {
    store.dispatch({
      type: actionTypes.SetViewExpired,
      payload: { isExpired: true },
    });

    changeRoute("/too-many-requests");
    // return;
    let errorStatus = { status: "CYBER_ATTACK" };
    throw new Error(JSON.stringify(errorStatus));
  }

  if (handleServerErrors && response.status === 403) {
    changeRoute("/not-authorized");

    let errorStatus = { status: "FORBIDDEN" };
    throw new Error(JSON.stringify(errorStatus));
    // return;
  }

  if (response.status === 400) {
    let errorMessage = { status: "BAD_REQUEST" };
    let resp = null;
    try {
      resp = await response.json();
    } catch (e) {
      resp = e;
    }

    errorMessage = { ...errorMessage, data: resp };
    throw new Error(JSON.stringify(errorMessage));
  }

  // Need to send response, NOT response.json
  return response;
};

const simpleFetch = async (url, data = {}, handleServerErrors = true) => {
  let response = await fetch(url, {
    ...data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (handleServerErrors && response.status >= 500) {
    changeRoute("/error");
    // return;
    let errorStatus = { status: "ERROR" };
    throw new Error(JSON.stringify(errorStatus));
  }

  if (response.status === 429 || response.status === 413) {
    store.dispatch({
      type: actionTypes.SetViewExpired,
      payload: { isExpired: true },
    });

    changeRoute("/too-many-requests");
    // return;
    let errorStatus = { status: "CYBER_ATTACK" };
    throw new Error(JSON.stringify(errorStatus));
  }

  if (response.status === 400) {
    let errorMessage = { status: "BAD_REQUEST" };
    let resp = null;
    try {
      resp = await response.json();
    } catch (e) {
      resp = e;
    }

    errorMessage = { ...errorMessage, data: resp };
    throw new Error(JSON.stringify(errorMessage));
  }

  // Need to send response, NOT response.json
  return response;
};

export { simpleFetch, fetchWithFormData };
export default authenticatedFetch;
