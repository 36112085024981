import React from "react";
import unreadIcon from "../../assets/images/green-dot.svg";
import optionsIcon from "../../assets/images/dots-more.svg";
import importantIcon from "../../assets/images/exclaim.svg";
import classes from "./NotificationBar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../redux/auth-context";
import { useDispatch } from "react-redux";
import { actionTypes as authActionTypes } from "../../pages/_redux/authRedux";

const Notification = ({ data }) => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const dispatch = useDispatch();

  const proceedTo = (notification) => {
    if (notification.type === "SELLER_ACCOUNT_APPROVAL_PENDING") {
      dispatch({
        type: authActionTypes.UpdateMiscData,
        payload: {
          sellerApprovalStatus: "PENDING",
        },
      });

      navigate("/seller-approval-requests");
      return;
    } else if (notification.type === "SELLER_ACCOUNT_APPROVAL_DEFERRED") {
      dispatch({
        type: authActionTypes.UpdateMiscData,
        payload: {
          sellerApprovalStatus: "DEFERRED",
        },
      });

      navigate("/seller-approval-requests");
      return;
    } else if (notification.type === "IDENTITY_VERIFICATION_PENDING") {
      navigate("/identity-verification-requests");
      return;
    } else if (notification.type === "DEMO_REQUESTS_PENDING") {
      navigate("/demo-requests");
      return;
    }
  };

  return (
    <>
      <div className={`row ${classes.bar}`}>
        {false && (
          <div className="col-auto">
            <div className={`d-flex ${classes.icons} `}>
              {false && <img src={unreadIcon} />}
              <img src={importantIcon} />
            </div>
          </div>
        )}

        <div className="col">
          <div>
            {data.type === "SELLER_ACCOUNT_APPROVAL_PENDING" && (
              <Link
                className={classes.notificationLink}
                onClick={(e) => {
                  e.preventDefault();
                  proceedTo(data);
                }}
              >
                Seller Account Pending for Approval <b>{data.count}</b>
              </Link>
            )}

            {data.type === "SELLER_ACCOUNT_APPROVAL_DEFERRED" && (
              <Link
                className={classes.notificationLink}
                onClick={(e) => {
                  e.preventDefault();
                  proceedTo(data);
                }}
              >
                Deferred Seller Account Pending for Approval <b>{data.count}</b>
              </Link>
            )}

            {data.type === "IDENTITY_VERIFICATION_PENDING" && (
              <Link
                className={classes.notificationLink}
                onClick={(e) => {
                  e.preventDefault();
                  proceedTo(data);
                }}
              >
                Identity Verification Requests Pending for Approval{" "}
                <b>{data.count}</b>
              </Link>
            )}

            {data.type === "DEMO_REQUESTS_PENDING" && (
              <Link
                className={classes.notificationLink}
                onClick={(e) => {
                  e.preventDefault();
                  proceedTo(data);
                }}
              >
                Demo Requests Pending for Approval <b>{data.count}</b>
              </Link>
            )}
          </div>
        </div>
        {false && (
          <div className="col-auto pl-0">
            <Link>
              <img src={optionsIcon} />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export default Notification;
