import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  AUCTION_LISTING_REPORT_URL,
  AUCTION_TYPE_LIBRARY_URL,
  ITEM_STATUS_LIBRARY_URL,
  AUCTION_LISTING_REPORT_DOWNLOAD_URL,
  AUCTION_LISTING_REMOVE_ITEM_URL
} from "../../Constants";
import { fetchRecords, downloadCSV } from "../../services/ReportsService";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Formik } from "formik";
import { Select } from "../../components/Select";
import ZDatePicker from "../../components/form/ZDatePicker";
import Label from "../../components/UI/Label";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import moment from "moment";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import { LibraryService } from "../../services/LibraryService";
import Alert from "../../components/UI/Alert";
import ConfirmModal from "../../components/UI/ConfirmModal";

const AuctionListingReport = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState();

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [selectedAuctionId, setSelectedAuctionId] = useState();
  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();
  const [values, setValues] = useState([]);
  const [itemStatuses, setItemsStatuses] = useState([]);
  const [auctionTypes, setAuctionTypes] = useState([]);
  const [removeMessage, setRemoveMessage] = useState();

  const fetchReportData = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?" +
        Object.keys(values)
          .map((key) => key + "=" + values[key])
          .join("&");

      const rs = await fetchRecords(
        AUCTION_LISTING_REPORT_URL + statusParam + pagingParams
      );
      console.log(rs);
      setItemList(rs?.content);

      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const downloadReportData = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?" +
        Object.keys(values)
          .map((key) => key + "=" + values[key])
          .join("&");

      const rs = await downloadCSV(
        AUCTION_LISTING_REPORT_DOWNLOAD_URL + statusParam + pagingParams
      );
      console.log(rs);
      const url = window.URL.createObjectURL(new Blob([rs]));

      const link = document.createElement("a");
      link.href = url;
      link.download = "auction-listing-report.csv";

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const removeAuctionItem = async (value) => {
    ctx.showProgress(true);
    try {
      const requestParam = "?auctionId="+value ;

      console.log(requestParam);

      const rs = await fetchRecords(
        AUCTION_LISTING_REMOVE_ITEM_URL + requestParam
      );
      console.log(rs);
      fetchReportData();
      setRemoveMessage(rs.message);
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    ctx.showProgress(true);
    const asyncFn = async () => {
      try {
        let itemStatusList = await fetchRecords(`${ITEM_STATUS_LIBRARY_URL}`);
        itemStatusList && setItemsStatuses(itemStatusList);

        let auctionTypesList = await fetchRecords(`${AUCTION_TYPE_LIBRARY_URL}`);
        auctionTypesList && setAuctionTypes(auctionTypesList);

        // ctx.showProgress(false);
      } catch (error) {
        // ctx.showProgress(false);
      }
    };
    asyncFn();
    fetchReportData();
  }, []);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchReportData();
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchReportData();
  }, [values]);

  const filterInitialValues = {
    fromDate: "",
    toDate: "",
    sellerId: "",
    lotNumber: "",
    auctionType: "",
    status: "",
  };

  const filterValidationSchema = Yup.object().shape({
    fromDate: Yup.date().nullable().typeError("Invalid date"),
    toDate: Yup.date().nullable().typeError("Invalid date"),
    sellerId: Yup.string(),
    lotNumber: Yup.string(),
    auctionType: Yup.string(),
    status: Yup.string(),
  });

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to remove this auction item?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            removeAuctionItem(selectedAuctionId);
          }}
        />
      )}
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading title="Auction Listing Report" headingSize="sm">
                <Link
                  onClick={() => downloadReportData()}
                  className="font-size-16"
                >
                  Download CSV
                </Link>{" "}
                {" | "}
                <Link to="/seller-approval-requests" className="font-size-16">
                  Back to Main Menu
                </Link>
              </PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={filterInitialValues}
                    // validationSchema={filterValidationSchema}
                    onSubmit={(values) => {
                      console.log("submitted values", values);

                      const submitted = {
                        ...values,
                        fromDate: values?.fromDate
                          ? moment(new Date(values?.fromDate)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                        toDate: values?.toDate
                          ? moment(new Date(values?.toDate)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                      };

                      const valArr = [];
                      for (const [key, value] of Object.entries(submitted)) {
                        console.log(`${key}: ${value}`);
                        valArr[key] = value;
                      }

                      setValues(valArr);
                    }}
                  >
                    {({
                      handleSubmit,
                      resetForm,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form>
                        <div className="row">                         
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="sellerId"
                                component={ZInputComponent}
                                placeholder="Seller ID"
                              />

                              <div className="mt-2">
                                <Field
                                  className="form-control"
                                  name="lotNumber"
                                  component={ZInputComponent}
                                  placeholder="Lot #"
                                />
                              </div>

                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="Posting Date From"
                                maxDate={new Date()}
                                name="fromDate"
                              />

                              <div className="mt-2">
                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="Posting Date To"
                                name="toDate"
                              />
                            </div>
                            </div>
                          </div>

                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Select
                                name="auctionType"
                                className="form-control"
                                defaultTouch
                                onChange={(e) => {
                                  setFieldValue(
                                    "auctionType",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="" disabled>
                                  Select Auction Type
                                </option>

                                {auctionTypes && auctionTypes?.map((item) => {
                                  return (
                                    <option key={item} value={item}>
                                      {item.replaceAll("_", " ")}
                                    </option>
                                  );
                                })}
                              </Select>

                              <div className="mt-2">
                                <Select
                                  name="status"
                                  className="form-control"
                                  defaultTouch
                                  onChange={(e) => {
                                    setFieldValue(
                                      "status",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Status
                                  </option>

                                  {itemStatuses && itemStatuses?.map((item) => {
                                    return (
                                      <option key={item} value={item}>
                                        {item.replaceAll("_", " ")}
                                      </option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-lg-3">
                            <div
                              className={`js-form-message mb-4 ${tableClasses.buttonsAlignment} `}
                            >
                              <button
                                className="btn btn-secondary px-5 mr-2"
                                type="button"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  resetForm();
                                  setValues([]);
                                }}
                              >
                                Reset
                              </button>
                              <button
                                className="btn btn-primary-dark-w px-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  console.log("errors", res);
                                  if (res && Object.values(res)?.length > 0) {
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="row">
                {removeMessage && (
                  <div className="col-12">
                    <Alert type="info" children={removeMessage} />
                  </div>
                )}  
                <div className="col-12 mt-2">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <AuctionListingReport.TableBodyRow
                          key={i}
                          auctionID={item.auctionID}
                          auctionStatus={item.auctionStatus}
                          auctionType={item.auctionType}
                          currency={item.currency}
                          currentBid={item.currentBid}
                          endDate={item.endDate}
                          highestBidderContactnumber={
                            item.highestBidderContactnumber
                          }
                          highestBidderEmailAddress={
                            item.highestBidderEmailAddress
                          }
                          highestBidderId={item.highestBidderId}
                          highestBidderName={item.highestBidderName}
                          itemImage={item.itemImage}
                          itemName={item.itemName}
                          numberOfBids={item.numberOfBids}
                          paymentStatus={item.paymentStatus}
                          phoneType={item.phoneType}
                          reservePrice={item.reservePrice}
                          startDate={item.startDate}
                          startingPrice={item.startingPrice}
                          sellerId={item.sellerId}
                          sellerName={item.sellerName}
                          sellerAgency={item.sellerAgency}
                          itemPostingDate={item.itemPostingDate}
                          setShowConfirmBox={setShowConfirmBox}
                          setSelectedAuctionId={setSelectedAuctionId}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <AuctionListingReport.MobileBodyRow
                          key={i}
                          auctionID={item.auctionID}
                          auctionStatus={item.auctionStatus}
                          auctionType={item.auctionType}
                          currency={item.currency}
                          currentBid={item.currentBid}
                          endDate={item.endDate}
                          highestBidderContactnumber={
                            item.highestBidderContactnumber
                          }
                          highestBidderEmailAddress={
                            item.highestBidderEmailAddress
                          }
                          highestBidderId={item.highestBidderId}
                          highestBidderName={item.highestBidderName}
                          itemImage={item.itemImage}
                          itemName={item.itemName}
                          numberOfBids={item.numberOfBids}
                          paymentStatus={item.paymentStatus}
                          phoneType={item.phoneType}
                          reservePrice={item.reservePrice}
                          startDate={item.startDate}
                          startingPrice={item.startingPrice}
                          sellerId={item.sellerId}
                          sellerName={item.sellerName}
                          sellerAgency={item.sellerAgency}
                          itemPostingDate={item.itemPostingDate}
                          setShowConfirmBox={setShowConfirmBox}
                          setSelectedAuctionId={setSelectedAuctionId}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

AuctionListingReport.TableBodyRow = ({
  auctionID,
  auctionStatus,
  auctionType,
  currency,
  currentBid,
  endDate,
  highestBidderContactnumber,
  highestBidderEmailAddress,
  highestBidderId,
  highestBidderName,
  itemImage,
  itemName,
  numberOfBids,
  paymentStatus,
  phoneType,
  reservePrice,
  startDate,
  startingPrice,
  sellerId,
  sellerName,
  sellerAgency,
  itemPostingDate,
  setShowConfirmBox,
  setSelectedAuctionId,
}) => {
  return (
    <>
      <tr>
        <td style={{ width: "12%" }}>
          <div className="mt-2">
            <img
              className="w-90"
              style={{ height: "110px" }}
              src={itemImage}
              alt="auction item image"
            />
          </div>
        </td>
        <td>
          <div className={`${tableClasses.label}`}>
            <span className="text-uppercase font-weight-bold">{auctionID}</span>{" "}
            |{" "}
            <span className="text-capitalize">
              {auctionStatus.replaceAll("_", " ")}
            </span>
          </div>
          <div className={tableClasses.heading}>{itemName}</div>
          <div className="mt-2">
            <span className={tableClasses.label}>Current Bid: </span>
            <span className={tableClasses.value}> {currentBid}</span>
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Auction Type: </span>
            <span className={tableClasses.value}> {auctionType}</span>
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Item Posting Date: </span>
            <span className={tableClasses.value}> {itemPostingDate}</span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Seller ID | Name</div>
          <div className={tableClasses.value}>{sellerId} | {sellerName}</div>
          <div className={tableClasses.label}>Seller Agency</div>
          <div className={tableClasses.value}>{sellerAgency}</div>
          <div className={tableClasses.label}>Number of Bids</div>
          <div className={tableClasses.value}>{numberOfBids}</div>
          <div className={tableClasses.label}>Payment Status</div>
          <div className={tableClasses.value}>
            {paymentStatus.replaceAll("_", " ")}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Starting Price</div>
          <div className={tableClasses.value}>
            {currency} {formatUSD(startingPrice)}
          </div>
          <div className={tableClasses.label}>Reserve Price</div>
          <div className={tableClasses.value}>
            {currency} {formatUSD(reservePrice)}
          </div>
          <div className={tableClasses.label}>Start Date</div>
          <div className={tableClasses.value}>{startDate} </div>
          <div className={tableClasses.label}>End Date</div>
          <div className={tableClasses.value}>{endDate}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Higest Bidder ID | Name</div>
          <div className={tableClasses.value}>
            {highestBidderId ? highestBidderId : "n/a"} {" | "}{" "}
            {highestBidderName ? highestBidderName : "n/a"}
          </div>
          <div className={tableClasses.label}>Higest Bidder Email</div>
          <div className={tableClasses.value}>
            {highestBidderEmailAddress ? highestBidderEmailAddress : "n/a"}
          </div>
          <div className={tableClasses.label}>Higest Bidder Contact Type</div>
          <div className={tableClasses.value}>
            {phoneType ? phoneType : "n/a"}{" "}
          </div>
          <div className={tableClasses.label}>Higest Bidder Contact Number</div>
          <div className={tableClasses.value}>
            {highestBidderContactnumber ? highestBidderContactnumber : "n/a"}
          </div>
        </td>
        <td>
          <button
            className="btn btn-primary-dark-w px-5"
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              setShowConfirmBox(true);
              setSelectedAuctionId(auctionID);
            }}
          >
            Remove Item
          </button>
        </td>
      </tr>
    </>
  );
};

AuctionListingReport.MobileBodyRow = ({
  auctionID,
  auctionStatus,
  auctionType,
  currency,
  currentBid,
  endDate,
  highestBidderContactnumber,
  highestBidderEmailAddress,
  highestBidderId,
  highestBidderName,
  itemImage,
  itemName,
  numberOfBids,
  paymentStatus,
  phoneType,
  reservePrice,
  startDate,
  startingPrice,
  sellerId,
  sellerName,
  sellerAgency,
  itemPostingDate,
  setShowConfirmBox,
  setSelectedAuctionId,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={`${tableClasses.blockText} w-100`}>
          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <img
                className="w-100"
                style={{ height: "225px" }}
                src={itemImage}
                alt="auction item image"
              />
            </div>
          </div>
          <div className={`${tableClasses.label} mt-2`}>
            <span className="text-uppercase font-weight-bold">{auctionID}</span>{" "}
            |{" "}
            <span className="text-capitalize">
              {auctionStatus.replaceAll("_", " ")}
            </span>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Item Name</div>
            <div className={tableClasses.value}>{itemName}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Current Bid: </div>
            <div className={tableClasses.value}> {currentBid}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Auction Type: </div>
            <div className={tableClasses.value}> {auctionType}</div>
          </div>

          <div className="mt-2">
            <div className={tableClasses.label}>Item Posting Date: </div>
            <div className={tableClasses.value}> {itemPostingDate}</div>
          </div>

          <div className={tableClasses.item}>
          <div className={tableClasses.label}>Seller ID | Name</div>
          <div className={tableClasses.value}>{sellerId} | {sellerName}</div>
          </div>
          <div className={tableClasses.item}>
          <div className={tableClasses.label}>Seller Agency</div>
          <div className={tableClasses.value}>{sellerAgency}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Number of Bids</div>
            <div className={tableClasses.value}>{numberOfBids}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Status</div>
            <div className={tableClasses.value}>{paymentStatus.replaceAll("_", " ")}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Number of Bids</div>
            <div className={tableClasses.value}>{numberOfBids}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Status</div>
            <div className={tableClasses.value}>
              {paymentStatus.replaceAll("_", " ")}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Starting Price</div>
            <div className={tableClasses.value}>
              {currency} {formatUSD(startingPrice)}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Reserve Price</div>
            <div className={tableClasses.value}>
              {currency} {formatUSD(reservePrice)}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Start Date</div>
            <div className={tableClasses.value}>{startDate} </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>End Date</div>
            <div className={tableClasses.value}>{endDate}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Higest Bidder ID | Name</div>
            <div className={tableClasses.value}>
              {highestBidderId ? highestBidderId : "n/a"} {" | "}{" "}
              {highestBidderName ? highestBidderName : "n/a"}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Higest Bidder Email</div>
            <div className={tableClasses.value}>
              {highestBidderEmailAddress ? highestBidderEmailAddress : "n/a"}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Higest Bidder Contact Type</div>
            <div className={tableClasses.value}>
              {phoneType ? phoneType : "n/a"}{" "}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>
              Higest Bidder Contact Number
            </div>
            <div className={tableClasses.value}>
              {highestBidderContactnumber ? highestBidderContactnumber : "n/a"}
            </div>
          </div>

          <div className="mt-4 text-center">
            <button
              className="btn btn-primary-dark-w px-5"
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                setShowConfirmBox(true);
                setSelectedAuctionId(auctionID);
              }}
            >
              Remove Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionListingReport;
