import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import {
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import { actionTypes as authActionTypes } from "../_redux/authRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { REGISTERED_BIDDER_ACCOUNT_LIST_URL } from "../../Constants";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import { fetchRecords } from "../../services/BidderService";

const RegisteredBidders = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const { miscData } = useSelector(
    ({ auth }) => ({
      miscData: auth.miscData,
    }),
    shallowEqual
  );

  const fetchMyItems = async () => {
    ctx.showProgress(true);
    console.log(miscData);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const searchValues = [];
      if (miscData?.regBidderId)
        searchValues.push("bidderId=" + miscData?.regBidderId);
      if (miscData?.regBidderName)
        searchValues.push("bidderName=" + miscData?.regBidderName);
      if (miscData?.regContactNumber)
        searchValues.push("contactNumber=" + miscData?.regContactNumber);
      if (miscData?.bidderStatus)
        searchValues.push("status=" + miscData?.bidderStatus);

      let searchParams = searchValues.join("&");
      searchParams = pagingParams ? "&" + searchParams : searchParams;

      const fetchURL = REGISTERED_BIDDER_ACCOUNT_LIST_URL;

      const rs = await fetchRecords(
        fetchURL + "?" + pagingParams + searchParams
      );
      console.log("records", rs);

      const content = rs?.content;
      setItemList(content);

      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchMyItems();
  }, []);

  // useEffect(() => {
  //   fetchMyItems();
  // }, [miscData]);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchMyItems();
  }, [pageSize, currentPage]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                bidderManagementActive
                subMenu={"registered-bidders"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              <PlaceholderHeading title="Registered Bidders"></PlaceholderHeading>
              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row mt-4">
                <div className="col mb-2">
                  <input
                    name="bidderId"
                    className="form-control"
                    placeholder="Bidder Id"
                    value={miscData?.regBidderId}
                    onChange={(e) => {
                      dispatch({
                        type: authActionTypes.UpdateMiscData,
                        payload: {
                          regBidderId: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col mb-2">
                  <input
                    name="bidderName"
                    className="form-control"
                    placeholder="Bidder Name"
                    value={miscData?.regBidderName}
                    onChange={(e) => {
                      dispatch({
                        type: authActionTypes.UpdateMiscData,
                        payload: {
                          regBidderName: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col mb-2">
                  <input
                    name="contactNumber"
                    className="form-control"
                    placeholder="Contact Number"
                    value={miscData?.regContactNumber}
                    onChange={(e) => {
                      dispatch({
                        type: authActionTypes.UpdateMiscData,
                        payload: {
                          regContactNumber: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col mb-2">
                  <select
                    name="status"
                    className="form-control"
                    value={miscData?.bidderStatus}
                    onChange={(e) => {
                      dispatch({
                        type: authActionTypes.UpdateMiscData,
                        payload: {
                          bidderStatus: e.target.value,
                        },
                      });
                    }}
                  >
                    <option key="ACTIVE" value="ACTIVE">
                      ACTIVE
                    </option>
                    <option key="BLOCKED" value="BLOCKED">
                      BANNED
                    </option>
                  </select>
                </div>
                <div className="col-auto mb-2 text-right">
                  <div className="js-form-message mb-2">
                    <button
                      className="btn btn-secondary px-5 mr-2"
                      type="button"
                      onClick={async (e) => {
                        e.preventDefault();
                        dispatch({
                          type: authActionTypes.UpdateMiscData,
                          payload: {
                            regBidderId: "",
                            regBidderName: "",
                            regContactNumber: "",
                            regStatus: "ACTIVE",
                          },
                        });
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-primary-dark-w px-5"
                      type="submit"
                      onClick={async (e) => {
                        e.preventDefault();
                        fetchMyItems();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-2">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <RegisteredBidders.TableBodyRow
                          key={i}
                          data={item}
                          approvalClicked={(bidderId) => {
                            navigate("/registered-bidder/" + bidderId);
                          }}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <RegisteredBidders.MobileBodyRow
                          key={i}
                          data={item}
                          approvalClicked={(bidderId) => {
                            navigate("/registered-bidder/" + bidderId);
                          }}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage + 1}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

RegisteredBidders.TableBodyRow = ({
  data,
  approvalClicked = (bidderId) => {},
}) => {
  return (
    <>
      <tr>
        <td>
          <div className={tableClasses.label}>Name</div>
          <div className={tableClasses.value}>
            {data?.firstName} {data?.lastName}
          </div>

          <div className={tableClasses.label}>Email</div>
          <div className={tableClasses.value}>{data?.email}</div>

          <div className="mt-2">
            <span className={tableClasses.label}>Bidder Id: </span>
            <span className={tableClasses.value}>{data?.userId}</span>
          </div>

          <div className="mt-2">
            <span className={tableClasses.label}>Applied On: </span>
            <span className={tableClasses.value}>
              {formattedUTCtoUSCentral(data?.createdOn)}
            </span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Company</div>
          <div className={tableClasses.value}>
            {data?.bidder?.company ? data?.bidder?.company : "n/a"}
          </div>

          <div className={tableClasses.label}>VAT Registration</div>
          <div className={tableClasses.value}>
            {data?.bidder?.vatRegistration
              ? data?.bidder?.vatRegistration
              : "n/a"}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>City</div>
          <div className={tableClasses.value}>
            {data?.bidder?.city?.value}, {data?.bidder?.state?.value}
          </div>

          <div className={tableClasses.label}>Country</div>
          <div className={tableClasses.value}>
            {data?.bidder?.country?.value}
          </div>
        </td>

        <td>
          <a
            href="#"
            className="btn btn-block py-1 btn-primary-dark-w"
            onClick={(e) => {
              e.preventDefault();
              approvalClicked(data?.userId);
            }}
          >
            {data?.status === "ACTIVE" && "BAN BIDDER"}
            {data?.status !== "ACTIVE" && "ACTIVATE BIDDER"}
          </a>
        </td>
      </tr>
    </>
  );
};

RegisteredBidders.MobileBodyRow = ({
  data,
  approvalClicked = (bidderId) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={`${tableClasses.block} ${tableClasses.buttons}`}>
        <div className={tableClasses.buttonRow}>
          <div className={tableClasses.value}>
            <a
              href="#"
              className={tableClasses.link}
              onClick={(e) => {
                e.preventDefault();
                approvalClicked(data?.userId);
              }}
            >
              {data?.status === "ACTIVE" && "BAN BIDDER"}
              {data?.status !== "ACTIVE" && "ACTIVATE BIDDER"}
            </a>
          </div>
        </div>
      </div>

      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.heading}>
              {data?.firstName} {data?.lastName}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Bidder Id</div>
            <div className={tableClasses.value}>{data?.userId}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Email</div>
            <div className={tableClasses.value}>{data?.email}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Applied On</div>
            <div className={tableClasses.value}>
              {formattedUTCtoUSCentral(data?.createdOn)}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Company</div>
            <div className={tableClasses.value}>
              {data?.bidder?.company ? data?.bidder?.company : "n/a"}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>VAT Registration</div>
            <div className={tableClasses.value}>
              {data?.bidder?.vatRegistration
                ? data?.bidder?.vatRegistration
                : "n/a"}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>City</div>
            <div className={tableClasses.value}>
              {data?.bidder?.city?.value}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>State</div>
            <div className={tableClasses.value}>
              {data?.bidder?.state?.value}
            </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Country</div>
            <div className={tableClasses.value}>
              {data?.bidder?.country?.value}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredBidders;
