import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Seller.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import LotsTable from "../../components/table/LotsTable";
import {
  deflateErrors,
  formatUSD,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Label from "../../components/UI/Label";
import {
  REGISTERED_SELLER_DETAIL_URL,
  REGISTERED_SELLER_STATUS_ACTIVE_URL,
  REGISTERED_SELLER_STATUS_BAN_URL,
} from "../../Constants";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";
import {
  buyerFeeUpdate,
  fetchRecords,
  sellerLoginStatusUpdate,
} from "../../services/SellerService";
import * as Yup from "yup";
import ErrorMessage from "../../components/UI/ErrorMessage";
import ViewField from "../../components/UI/ViewField";

const RegisteredSellerFeeUpdate = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [detail, setDetail] = useState({});

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [buyerFeeValue, setBuyerFeeValue] = useState();

  const [serverErrors, setServerErrors] = useState();

  const fetchData = async (sellerId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchRecords(
        REGISTERED_SELLER_DETAIL_URL.replace("{sellerId}", sellerId)
      );
      console.log("item", rs);
      setDetail(rs);
      setBuyerFeeValue(rs?.seller?.buyerFee);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const validateForm = () => {
    if (!buyerFeeValue || buyerFeeValue.trim() == "") {
      setServerErrors({ buyerFee: "Please enter buyer premium" });
      return false;
    }

    if (parseInt(buyerFeeValue) < 0 || parseInt(buyerFeeValue) > 100) {
      setServerErrors({
        buyerFee: "Please enter valid buyer premium",
      });
      return false;
    }

    return true;
  };

  const submitForm = async (userId) => {
    ctx.showProgress(true);
    await updateRequest(userId, {
      buyerFee: buyerFeeValue,
    });
  };

  const updateRequest = async (userId, body) => {
    buyerFeeUpdate(userId, body)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/registered-sellers");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;

        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        const json = JSON.parse(e.message);
        if (json.status !== "BAD_REQUEST") {
          return;
        }

        if (json.data.status === "VALIDATION_FAILED") {
          setServerErrors({
            ...deflateErrors(json.data.errors),
          });
        }
      });
  };

  useEffect(() => {
    if (params.sellerId) fetchData(params.sellerId);
  }, [params.sellerId]);

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to update buyer's premium?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            submitForm(detail?.userId);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                sellerManagementActive
                subMenu={"registered-sellers"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Seller Account Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col">
                  <h4>
                    {detail?.firstName} {detail?.lastName}
                  </h4>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Email Address" value={detail?.email} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Applied On" value={detail?.createdOn} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Source"
                        value={detail?.creationSource}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Certificate Number"
                        value={detail?.seller?.certificateNumber}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Seller Type"
                        value={detail?.seller?.type}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Agency"
                        value={detail?.seller?.agencyName}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Street"
                        value={detail?.seller?.street}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="P.O Box"
                        value={detail?.seller?.poBox}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Zip Code"
                        value={detail?.seller?.zipCode}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="City"
                        value={detail?.seller?.city?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="State"
                        value={detail?.seller?.state?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Country"
                        value={detail?.seller?.country?.value}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className="row">
                  <div className="col-md-4 col-12 my-2">
                    <div className="js-form-message">
                      <Label hightlighted>Buyer's Premium</Label>
                      <input
                        type="number"
                        className="form-control"
                        name="buyerFeeValue"
                        placeholder="Enter buyer's premium"
                        value={buyerFeeValue}
                        onChange={(e) => {
                          setBuyerFeeValue(e?.target?.value);
                        }}
                      />
                    </div>

                    {serverErrors?.buyerFee && (
                      <ErrorMessage>{serverErrors?.buyerFee}</ErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <ButtonsPanel>
                  <a
                    href="#"
                    className={`btn btn-secondary-dark-w ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/registered-sellers");
                    }}
                  >
                    CANCEL
                  </a>

                  <a
                    href="#"
                    className={`btn btn-secondary ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!validateForm()) {
                        return;
                      }

                      setShowConfirmBox(true);
                    }}
                  >
                    UPDATE BUYER PREMIUM
                  </a>
                </ButtonsPanel>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default RegisteredSellerFeeUpdate;
