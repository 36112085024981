import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import {
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import { actionTypes as authActionTypes } from "../_redux/authRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import DropDownButton from "../../components/button/DropDownButton";
import DropDownItem from "../../components/button/DropDownItem";
import {
  SELLER_DEMO_APPROVED_LIST_URL,
  SELLER_DEMO_PENDING_LIST_URL,
  SELLER_DEMO_REJECTED_LIST_URL,
} from "../../Constants";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import { fetchRecords } from "../../services/SellerService";

const DemoRequests = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const { miscData } = useSelector(
    ({ auth }) => ({
      miscData: auth.miscData,
    }),
    shallowEqual
  );

  const fetchMyItems = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "?" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      let fetchURL = SELLER_DEMO_PENDING_LIST_URL;
      if (miscData?.demoApprovalStatus === "APPROVED") {
        fetchURL = SELLER_DEMO_APPROVED_LIST_URL;
      } else if (miscData?.demoApprovalStatus === "REJECTED") {
        fetchURL = SELLER_DEMO_REJECTED_LIST_URL;
      }

      const rs = await fetchRecords(fetchURL + pagingParams);
      console.log("records", rs);

      const content = rs?.content;
      setItemList(content);

      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchMyItems();
  }, []);

  useEffect(() => {
    fetchMyItems();
  }, [miscData]);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchMyItems();
  }, [pageSize, currentPage]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                sellerManagementActive
                subMenu={"seller-demo-requests"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              <PlaceholderHeading title="Demo Requests"></PlaceholderHeading>
              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12">
                  <ButtonsPanel minWidth={`230px`}>
                    <DropDownButton
                      styles={{ minWidth: "200px" }}
                      onItemClicked={(v) => {
                        console.log(v);

                        dispatch({
                          type: authActionTypes.UpdateMiscData,
                          payload: {
                            demoApprovalStatus: v,
                          },
                        });
                      }}
                      selectedValue={miscData?.demoApprovalStatus}
                    >
                      <DropDownItem label="Pending" value="PENDING" />
                      <DropDownItem label="Approved" value="APPROVED" />
                      <DropDownItem label="Rejected" value="REJECTED" />
                    </DropDownButton>
                  </ButtonsPanel>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-3">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <DemoRequests.TableBodyRow
                          key={i}
                          data={item}
                          approvalClicked={(requestId) => {
                            navigate("/demo-approval/" + requestId);
                          }}
                          viewClicked={(requestId) => {
                            navigate("/demo-detail/" + requestId);
                          }}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <DemoRequests.MobileBodyRow
                          key={i}
                          data={item}
                          approvalClicked={(requestId) => {
                            navigate("/demo-approval/" + requestId);
                          }}
                          viewClicked={(requestId) => {
                            navigate("/demo-detail/" + requestId);
                          }}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

DemoRequests.TableBodyRow = ({
  data,
  approvalClicked = (meetingId) => {},
  viewClicked = (meetingId) => {},
}) => {
  return (
    <>
      <tr>
        <td>
          <div className={tableClasses.label}>Demo Request for</div>
          <div className={tableClasses.value}>
            {data?.dateForMeeting} {data?.timeForMeeting}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Duration</div>
          <div className={tableClasses.value}>
            {data?.durationOfMeeting} minutes
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Number of Guests</div>
          <div className={tableClasses.value}>{data?.guests?.length}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Applied On</div>
          <div className={tableClasses.value}>{data?.createdOn}</div>
        </td>

        <td>
          {data?.status === "PENDING" && (
            <a
              href="#"
              className="btn btn-block py-1 btn-primary-dark-w"
              onClick={(e) => {
                e.preventDefault();
                approvalClicked(data?.meetingId);
              }}
            >
              VIEW DETAIL
            </a>
          )}

          {(data?.status === "APPROVED" || data?.status === "REJECTED") && (
            <a
              href="#"
              className="btn btn-block py-1 btn-primary-dark-w"
              onClick={(e) => {
                e.preventDefault();
                viewClicked(data?.meetingId);
              }}
            >
              VIEW
            </a>
          )}
        </td>
      </tr>
    </>
  );
};

DemoRequests.MobileBodyRow = ({
  data,
  approvalClicked = (meetingId) => {},
  viewClicked = (meetingId) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={`${tableClasses.block} ${tableClasses.buttons}`}>
        <div className={tableClasses.buttonRow}>
          <div className={tableClasses.value}>
            {data?.status === "PENDING" && (
              <a
                href="#"
                className={tableClasses.link}
                onClick={(e) => {
                  e.preventDefault();
                  approvalClicked(data?.meetingId);
                }}
              >
                VIEW DETAIL
              </a>
            )}

            {(data?.status === "APPROVED" || data?.status === "REJECTED") && (
              <a
                href="#"
                className={tableClasses.link}
                onClick={(e) => {
                  e.preventDefault();
                  viewClicked(data?.meetingId);
                }}
              >
                VIEW
              </a>
            )}
          </div>
        </div>
      </div>

      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Demo Request for</div>
            <div className={tableClasses.value}>
              {data?.dateForMeeting} {data?.timeForMeeting}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Duration</div>
            <div className={tableClasses.value}>
              {data?.durationOfMeeting} minutes
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Number of Guests</div>
            <div className={tableClasses.value}>{data?.guests?.length}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Applied On</div>
            <div className={tableClasses.value}>{data?.createdOn}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoRequests;
