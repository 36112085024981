import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Listing.module.css";

import { MANAGE_PAYMENTS_DETAIL } from "../../Constants";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";
import ConfirmModal from "../../components/UI/ConfirmModal";

import {
  fetchData,
  paymentConfirmation,
} from "../../services/ManagePaymentService";
import {
  formatUSD,
  formattedUTCDatetoUSCentral,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";

const ConfirmPayment = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [itemDetail, setItemDetail] = useState({});
  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const fetchDetail = async (itemId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchData(
        MANAGE_PAYMENTS_DETAIL.replace("{itemId}", itemId)
      );
      console.log("item", rs);
      setItemDetail(rs);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    if (params.item) fetchDetail(params.item);
  }, [params.item]);

  const confirmPaymentReceived = async (itemId) => {
    ctx.showProgress(true);
    paymentConfirmation(itemId)
      .then((rs) => {
        ctx.showProgress(false);

        navigate("/manage-payments");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message="Do you confirm that you have received your payment?"
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            confirmPaymentReceived(itemDetail?.itemId);
          }}
        />
      )}
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myItemsActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Item Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col-auto">
                  <div className="d-lg-none d-block mb-3">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.saleType
                            ?.toLowerCase()
                            ?.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div
                      className={classes.imgContainer}
                      style={{ background: `url(${itemDetail.defaultImage})` }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-lg-block d-none">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.saleType
                            ?.toLowerCase()
                            ?.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <h4>{itemDetail.title}</h4>

                  <div
                    className="instructions-desc"
                    dangerouslySetInnerHTML={{ __html: itemDetail.description }}
                  />

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Start Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.startDate)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>End Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.endDate)}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Buyer Name</div>
                      <div className={classes.value}>
                        {itemDetail.winningBid?.name}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Buyer Email</div>
                      <div className={classes.value}>
                        {itemDetail.winningBid?.email}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Seller ID</div>
                      <div className={classes.value}>
                        {itemDetail?.sellerId}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Seller Name</div>
                      <div className={classes.value}>
                        {itemDetail?.sellerName}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Seller Contact</div>
                      <div className={classes.value}>
                        {itemDetail?.sellerContactNumber}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Payment Amount</div>
                      <div className={classes.value}>
                        {formatUSD(itemDetail.winningBid?.amount)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Payment Date</div>
                      <div className={classes.value}>
                        {formattedUTCDatetoUSCentral(itemDetail?.paymentDate)}
                      </div>
                    </div>

                    <div className="col-auto my-2">
                      <div className={classes.label}>Payment Method</div>
                      <div className={classes.value}>
                        {itemDetail?.paymentMethod?.replaceAll("_", " ")}
                      </div>
                    </div>

                    {itemDetail?.paymentMethod === "WIRE_TRANSFER" && (
                      <div className="col-auto my-2">
                        <div className={classes.label}>
                          Wire Transfer Receipt
                        </div>
                        <div className={classes.value}>
                          <a
                            href={itemDetail?.uploadedWireReceipt}
                            download={true}
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    )}

                    {itemDetail?.paymentMethod === "CASHIER_CHEQUE" && (
                      <div className="col-auto my-2">
                        <div className={classes.label}>
                          Cashier Cheque Image
                        </div>
                        <div className={classes.value}>
                          <a
                            href={itemDetail?.uploadedCashierCheque}
                            download={true}
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-5">
                    <ButtonsPanel>
                      <Link
                        to="/manage-payments"
                        className={`btn btn-secondary-dark-w ${buttonPanelClasses.item}`}
                      >
                        CANCEL
                      </Link>

                      <a
                        href="#"
                        className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowConfirmBox(true);
                        }}
                      >
                        CONFIRM PAYMENT
                      </a>
                    </ButtonsPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ConfirmPayment;
