import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Label from "../../components/UI/Label";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";
import ErrorMessage from "../../components/UI/ErrorMessage";
import DemoDetail from "./DemoDetail";
import { demoApproval } from "../../services/ScheduleDemoService";

const DemoDecision = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showRejectConfirmBox, setShowRejectConfirmBox] = useState(false);

  const [demoId, setDemoId] = useState();

  const [remarksValue, setRemarksValue] = useState();
  const [videoLink, setVideoLink] = useState();

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();

  const approve = async (demoId) => {
    ctx.showProgress(true);
    await approvalDecision({
      meetingId: demoId,
      remarks: remarksValue,
      videoLink: videoLink,
      decision: "APPROVED",
    });
  };

  const reject = async (demoId) => {
    ctx.showProgress(true);
    await approvalDecision({
      meetingId: demoId,
      remarks: remarksValue,
      decision: "REJECTED",
    });
  };

  const approvalDecision = async (requestBody) => {
    demoApproval(requestBody)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/demo-requests");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;

        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        const json = JSON.parse(e.message);
        if (json.status !== "BAD_REQUEST") {
          return;
        }

        if (json.data.status === "VALIDATION_FAILED") {
          setServerErrors({
            ...deflateErrors(json.data.errors),
          });
        }
      });
  };

  const validateForm = () => {
    if (!remarksValue || remarksValue.trim() == "") {
      setServerErrors({ remarks: "Please enter approval remarks" });

      return false;
    }

    return true;
  };

  const validateApproveForm = () => {
    if (!videoLink || videoLink.trim() == "") {
      setServerErrors({ videoLink: "Please enter video conference link" });

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (params.requestId) setDemoId(params.requestId);
  }, [params.requestId]);

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to approve this demo request?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            approve(demoId);
          }}
        />
      )}

      {showRejectConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to reject this demo request?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
            reject(demoId);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                sellerManagementActive
                subMenu={"seller-demo-requests"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              {demoId && <DemoDetail demoId={demoId} />}

              <div className="mt-5">
                <div className="js-form-message">
                  <Label hightlighted>Video Conference Link</Label>
                  <textarea
                    className="form-control"
                    name="videoLink"
                    placeholder="Enter video conference link"
                    onChange={(e) => {
                      setVideoLink(e?.target?.value);
                    }}
                  ></textarea>
                </div>

                {serverErrors?.videoLink && (
                  <ErrorMessage>{serverErrors?.videoLink}</ErrorMessage>
                )}
              </div>

              <div className="mt-5">
                <div className="js-form-message">
                  <Label hightlighted>Remarks</Label>
                  <textarea
                    className="form-control"
                    name="remarks"
                    placeholder="Enter approval remarks"
                    onChange={(e) => {
                      setRemarksValue(e?.target?.value);
                    }}
                  ></textarea>
                </div>

                {serverErrors?.remarks && (
                  <ErrorMessage>{serverErrors?.remarks}</ErrorMessage>
                )}
              </div>

              <div className="mt-5">
                <ButtonsPanel>
                  <a
                    href="#"
                    className={`btn btn-secondary ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!validateForm()) {
                        return;
                      }

                      setShowRejectConfirmBox(true);
                    }}
                  >
                    REJECT
                  </a>

                  <a
                    href="#"
                    className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!validateApproveForm()) {
                        return;
                      }

                      setShowConfirmBox(true);
                    }}
                  >
                    APPROVE
                  </a>
                </ButtonsPanel>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DemoDecision;
