import React from "react";
import classes from "./ViewField.module.css";

const ViewField = ({ label, value }) => {
  return (
    <div className={classes.view}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value ?? " "}</div>
    </div>
  );
};

export default ViewField;
