import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import classes from "./Footer.module.css";

const Footer = (props) => {
  return (
    <footer>
      <div className="border-top py-2">
        <div className="container">
          <div
            className={`flex-center-between d-block d-md-flex ${classes.copyRightsBar}`}
          >
            <div className="text-md-right">
              Copyrights © {new Date().getFullYear()} All Rights Reserved by
              BidlyAuction
            </div>
          </div>
        </div>
      </div>
      {/* End Footer-copy-right */}
    </footer>
  );
};

export default Footer;
