import authenticatedFetch, { simpleFetch } from "./fetch";
import {
  ADMIN_ALL_CONTACTS,
  ADMIN_ALL_CREATE,
  ADMIN_ALL_ROLES,
  ADMIN_ALL_UPDATE,
  ADMIN_USER_DELETE,
  ADMIN_USER_FIND,
  ADMIN_VIEW_CONTACTS,
} from "../Constants";

export async function searchAdminUsers(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function allContactUs(type) {
  const response = await authenticatedFetch(`${ADMIN_ALL_CONTACTS}/${type}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function viewConatct(contactId) {
  const response = await authenticatedFetch(
    `${ADMIN_VIEW_CONTACTS}${contactId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function allAdminRoles() {
  const response = await simpleFetch(`${ADMIN_ALL_ROLES}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export const createUser = async (requestBody) => {
  const response = await simpleFetch(`${ADMIN_ALL_CREATE}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 201 && response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const editUser = async (requestBody) => {
  const response = await simpleFetch(`${ADMIN_ALL_UPDATE}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 201 && response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export async function findUser(userId) {
  const response = await simpleFetch(`${ADMIN_USER_FIND}${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function removeUser(userId) {
  const response = await simpleFetch(`${ADMIN_USER_DELETE}${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}
