import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import storageSession from "redux-persist/lib/storage/session";

export const actionTypes = {
  Login: "Login Action",
  Logout: "Logout Action",
  SetViewExpired: "View Expired",
  UpdateLoggedInUser: "Update LoggedIn User",
  UpdateMiscData: "Misc Data",
};

const initialAuthState = {
  user: {},
  loggedInUser: {},
  token: undefined,
  refreshToken: undefined,
  viewExpired: false,
  miscData: {},
  roles: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "v11-bidly-auth",
    whitelist: [
      "loggedInUser",
      "token",
      "refreshToken",
      "user",
      "viewExpired",
      "miscData",
      "roles",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token, refreshToken, user, roles } = action.payload;
        return { ...state, token, refreshToken, user, roles };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("refreshToken");
        return initialAuthState;
      }

      case actionTypes.SetViewExpired: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...initialAuthState,
          viewExpired: action.payload.isExpired ?? false,
        };
      }
      case actionTypes.UpdateLoggedInUser: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          loggedInUser: { ...state.loggedInUser, ...action.payload },
        };
      }
      case actionTypes.UpdateMiscData: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          miscData: { ...state.miscData, ...action.payload },
        };
      }
      default:
        return state;
    }
  }
);
