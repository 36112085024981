//const BASE_URL = `http://localhost:9090`;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const BASE_URL_FRONTEND = process.env.REACT_APP_FRONTEND_URL;

// export const TIMEZONE_NAME = "US/Central";
export const TIMEZONE_NAME = "America/Chicago";
// export const TIMEZONE_NAME = "America/New_York";
export const DATETIME_SHOW_FORMAT = "MM/DD/YYYY hh:mm A";
export const DATE_SHOW_FORMAT = "MM/DD/YYYY";

export const SERVICE_URL = `${BASE_URL}/`;
export const BASE_URL_FRONTEND_URL = `${BASE_URL_FRONTEND}`;

export const FILTER_BASE_URL = `${BASE_URL}/library/filter`;
export const STATE_LIBRARY_URL = `${BASE_URL}/library/states/`;
export const COUNTRY_ITEM_COUNT_LIBRARY_URL = `${BASE_URL}/library/country/all/count`;
export const STATE_ITEM_COUNT_LIBRARY_URL = `${BASE_URL}/library/states/all/count`;
export const CITY_ITEM_COUNT_LIBRARY_URL = `${BASE_URL}/library/city/minified/count`;
export const CATEGORY_LIBRARY_URL = `${BASE_URL}/library/categories/`;
export const SUB_CATEGORY_LIBRARY_URL = `${BASE_URL}/library/subcategories/`;
export const CONDITION_LIBRARY_URL = `${BASE_URL}/library/conditions/`;
export const ALL_CONDITION_LIBRARY_URL = `${BASE_URL}/library/condition`;
export const SALES_TYPE_LIBRARY_URL = `${BASE_URL}/library/sale-types/`;
export const ACCOUNT_TYPE_LIBRARY_URL = `${BASE_URL}/library/account-types/`;

export const COUNTRIES_LIBRARY_URL = `${BASE_URL}/library/countries`;
export const COUNTRY_STATE_LIBRARY_URL = `${BASE_URL}/library/country/{countryCode}/state`;
export const STATE_CITY_LIBRARY_URL = `${BASE_URL}/library/state/{stateId}/city`;

export const CITIES_LIBRARY_URL = `${BASE_URL}/library/cities`;
export const ALL_STATES_LIBRARY_URL = `${BASE_URL}/library/states/all`;
export const STATE_CITIES_LIBRARY_URL = `${BASE_URL}/library/city-by-states/`;
export const DOCUMENT_TYPES_URL = `${BASE_URL}/library/document-types/`;
export const SELLER_URL = `${BASE_URL}/library/seller/all/count`;
export const CURRENCY_URL = `${BASE_URL}/library/currency`;
export const STARTING_PRICE_URL = `${BASE_URL}/library/starting-price`;
export const MENU_CATEGORIES_URL = `${BASE_URL}/library/category/menu`;
export const Auth_SERVICE_URL = `${BASE_URL}/auth/login`;
export const Auth_SERVICE_GOOGLE_URL = `${BASE_URL}/auth/google`;

export const CATEGORY_MENU_URL = `${BASE_URL}/library/category/subcategory/count`;
export const ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL = `${BASE_URL}/library/category/subcategory/all/count`;
export const ALL_REVIEWS = `${BASE_URL}/library/review`;
export const TOPIC_COUNT = `${BASE_URL}/library/topic`;
export const TOPIC_DETALS = `${BASE_URL}/library/topic/details`;

export const SEARCH_TERM_URL = `${BASE_URL}/auction/public/term`;

export const BIDS_WON_URL = `${BASE_URL}/bid/won/me`;
export const ITEM_BOUGHT_URL = `${BASE_URL}/bid/bought/me`;
export const BIDS_OPEN_URL = `${BASE_URL}/bid/open/me`;
export const BIDS_LOST_URL = `${BASE_URL}/bid/lost/me`;
export const BIDS_COMPLETED_URL = `${BASE_URL}/bid/completed/me`;
export const BOUGHT_COMPLETED_URL = `${BASE_URL}/bid/bought/completed/me`;

export const OFFERS_WON_URL = `${BASE_URL}/bid/offer/won/me`;
export const OFFERS_OPEN_URL = `${BASE_URL}/bid/offer/open/me`;
export const OFFERS_LOST_URL = `${BASE_URL}/bid/offer/lost/me`;
export const OFFERS_COMPLETED_URL = `${BASE_URL}/bid/offer/completed/me`;

export const INVOICE_ACCOUNT_INFO_URL = `${BASE_URL}/payment/auction/{item}/invoice/wire`;
export const INVOICE_CARD_PAYMENT_INIT_URL = `${BASE_URL}/payment/auction/{item}/init`;
export const INVOICE_CARD_PAYMENT_SUCESS_URL = `${BASE_URL}/payment/auction/{item}`;
export const WIRE_INVOICE_RECEIPT_UPLOAD_URL = `${BASE_URL}/payment/auction/{item}/invoice/wire`;

export const INBOX_URL = `${BASE_URL}/message/inbox`;
export const OUTBOX_URL = `${BASE_URL}/message/outbox`;
export const NEW_MESSAGE_URL = `${BASE_URL}/message/`;
export const SEND_REPLY_MESSAGE = `${BASE_URL}/message/{messageId}`;
export const VIEW_MESSAGE = `${BASE_URL}/message/{messageId}`;
export const NEW_MESSAGE_RECIPIENT_URL = `${BASE_URL}/message/recipient`;
export const MARK_READ_MESSAGE_URL = `${BASE_URL}/message/read`;

export const USER_MESSAGE_NOTIFICATION_URL = `${BASE_URL}/message/notification/me`;

export const USER_NOTIFICATIONS_URL = `${BASE_URL}/user/notification/`;
export const NOTIFICATION_WINNING_BID_INFO_URL = `${BASE_URL}/bid/won/{itemId}`;

export const MUNCIPALITY_POPULATION_LIBRARY_URL = `${BASE_URL}/library/muncipality-population`;
export const SELLER_LIST_CREATION_PLAN_LIBRARY_URL = `${BASE_URL}/library/list-creation-plan`;
export const AUCTION_TYPE_LIBRARY_URL = `${BASE_URL}/library/sale-types`;
export const ITEM_STATUS_LIBRARY_URL = `${BASE_URL}/library/item-status`;

export const MANAGE_PAYMENTS_LIST = `${BASE_URL}/user/payment/confirmation/`;
export const MANAGE_PAYMENTS_DETAIL = `${BASE_URL}/user/payment/confirmation/{itemId}`;
export const CONFIRM_PAYMENT = `${BASE_URL}/user/payment/confirmation/{itemId}`;

export const AUCTION_ITEM_SALES_REPORT_URL = `${BASE_URL}/user/report/sales`;
export const AUCTION_ITEM_SALES_REPORT_DETAILS_URL = `${BASE_URL}/user/report/sales/details`;
export const SALLER_OVERVIEW_REPORT_URL = `${BASE_URL}/user/report/seller-overview`;
export const SALLER_OVERVIEW_REPORT_DOWNLOAD_URL = `${BASE_URL}/user/report/seller-overview/download`;
export const SALLER_OVERVIEW_REPORT_DETAILS_BY_STATUS_URL = `${BASE_URL}/user/report/seller-overview/details`;
export const SELLER_DETAIL_URL = `${BASE_URL}/user/report/seller-overview/seller/{sellerId}`;
export const AUCTION_LISTING_REPORT_URL = `${BASE_URL}/user/report/auction-listing`;
export const AUCTION_LISTING_REMOVE_ITEM_URL = `${BASE_URL}/user/report/auction-listing/remove-item`;
export const AUCTION_LISTING_REPORT_DOWNLOAD_URL = `${BASE_URL}/user/report/auction-listing/download`;

export const newMessageErrorMsg =
  "Please fix the errors and click on send reply/message button to continue!";
export const genericErrorMsg =
  "Please fix the errors below and click on submit button to continue!";
export const wizardGenericErrorMsg =
  "Please fix the errors below and click on save and continue button to continue!";
export const genericTableErrorMsg =
  "Please remove highlighted row(s) and type again!";

export const maxFileUploadSize = 7340032;

export const LIVE_CHAT_LICENSE = 14911389;

export const ADMIN_ALL_USER = `${BASE_URL}/user/list`;
export const ADMIN_ALL_ROLES = `${BASE_URL}/user/roles`;
export const ADMIN_ALL_CREATE = `${BASE_URL}/user/create`;
export const ADMIN_ALL_UPDATE = `${BASE_URL}/user/update`;
export const ADMIN_USER_FIND = `${BASE_URL}/user/find/`;
export const ADMIN_USER_DELETE = `${BASE_URL}/user/delete/`;

export const SELLER_APPROVAL_ACCOUNT_LIST_URL = `${BASE_URL}/user/approval/seller/{status}/{sellerName}/{contactNumber}`;
export const SELLER_APPROVAL_DETAIL_URL = `${BASE_URL}/user/approval/seller/{sellerId}`;
export const SELLER_APPROVAL_DECISION_URL = `${BASE_URL}/user/approval/seller/`;

export const REGISTERED_SELLER_ACCOUNT_LIST_URL = `${BASE_URL}/user/seller/`;
export const REGISTERED_SELLER_DETAIL_URL = `${BASE_URL}/user/seller/{sellerId}`;
export const REGISTERED_SELLER_STATUS_ACTIVE_URL = `${BASE_URL}/user/seller/{sellerId}/active`;
export const REGISTERED_SELLER_STATUS_BAN_URL = `${BASE_URL}/user/seller/{sellerId}/ban`;
export const REGISTERED_SELLER_FEE_UPDATE_URL = `${BASE_URL}/user/seller/{sellerId}/fee`;

export const IDENTITY_VERIFICATION_PENDING_ACCOUNT_LIST_URL = `${BASE_URL}/user/verification/identity`;
export const IDENTITY_VERIFICATION_DETAIL_URL = `${BASE_URL}/user/verification/identity/{userId}`;
export const IDENTITY_VERIFICATION_DECISION_URL = `${BASE_URL}/user/verification/identity`;

export const REGISTERED_BIDDER_ACCOUNT_LIST_URL = `${BASE_URL}/user/bidder/`;
export const REGISTERED_BIDDER_DETAIL_URL = `${BASE_URL}/user/bidder/{bidderId}`;
export const REGISTERED_BIDDER_STATUS_ACTIVE_URL = `${BASE_URL}/user/bidder/{bidderId}/active`;
export const REGISTERED_BIDDER_STATUS_BAN_URL = `${BASE_URL}/user/bidder/{bidderId}/ban`;

export const SELLER_DEMO_PENDING_LIST_URL = `${BASE_URL}/user/demo/approval/pending`;
export const SELLER_DEMO_APPROVED_LIST_URL = `${BASE_URL}/user/demo/approval/approved`;
export const SELLER_DEMO_REJECTED_LIST_URL = `${BASE_URL}/user/demo/approval/rejected`;
export const SELLER_DEMO_DETAIL_URL = `${BASE_URL}/user/demo/approval/{demoId}`;
export const SELLER_DEMO_APPROVAL_URL = `${BASE_URL}/user/demo/approval`;

export const DASHBOARD_STATS_SELLER_MGT_URL = `${BASE_URL}/user/dashboard/me/seller-mgt`;
export const DASHBOARD_STATS_BIDDER_MGT_URL = `${BASE_URL}/user/dashboard/me/bidder-mgt`;
export const DASHBOARD_STATS_OTHER_URL = `${BASE_URL}/user/dashboard/me/other-stats`;
export const DASHBOARD_STATS_STATUS_URL = `${BASE_URL}/user/dashboard/me/status`;
export const DASHBOARD_STATS_SALES_INTERVAL_URL = `${BASE_URL}/user/dashboard/me/sales/interval`;
export const DASHBOARD_STATS_SALES_STATUS_URL = `${BASE_URL}/user/dashboard/me/sales/status`;
export const DASHBOARD_STATS_SALES_DAY_URL = `${BASE_URL}/user/dashboard/me/sales/day`;

export const AUCTION_CONFIG = `${BASE_URL}/config/auction`;
export const AUCTION_CONFIG_UPDATE = `${BASE_URL}/config/auction/update`;
export const AUCTION_PROBATION_UPDATE = `${BASE_URL}/config/auction//update/probation`;
export const AUCTION_PROBATION_CONFIG = `${BASE_URL}/config/auction/porbation/all`;

export const FEATURED_CATEGORIES_LIST_URL = `${BASE_URL}/config/category/featured`;
export const NON_FEATURED_CATEGORIES_LIST_URL = `${BASE_URL}/config/category/non-featured`;
export const MARK_FEATURED_CATEGORY_URL = `${BASE_URL}/config/category/featured`;
export const UPLOAD_FEATURED_CATEGORY_IMAGE_URL = `${BASE_URL}/config/category/image`;
export const REMOVE_FEATURED_CATEGORY_URL = `${BASE_URL}/config/category/featured/{categoryId}`;

export const ADMIN_ALL_CONTACTS = `${BASE_URL}/config/auction/contactus/all`;
export const ADMIN_VIEW_CONTACTS = `${BASE_URL}/config/auction//view/contact/`;
