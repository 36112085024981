import React from "react";
import { forwardRef } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import classes from "./Form.module.css";
import ErrorMessage from "../UI/ErrorMessage";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";

const ExampleCustomInput = forwardRef(
  ({ value, onClick, onChange, placeholder }, ref) => {
    // console.log(
    //   "changr valur",
    //   valueDateFormat,
    //   moment(new Date(value)).format(valueDateFormat)
    // );

    let formattedValue = value;
    // if (formattedValue) {
    //   formattedValue = moment(new Date(value)).format("MM/DD/YYYY h:mm A");
    // }

    return (
      <input
        value={formattedValue}
        placeholder={placeholder}
        className={`${classes.dateCalender} form-control`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
      />
    );
  }
);

export const ZDatePicker = ({
  className = "form-control",
  errors,
  serverError,
  maxDate,
  minDate,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  let fieldError = errors[field.name];
  if (field?.name?.indexOf(".") !== -1) {
    const names = field.name?.split(".");
    if (names?.length > 1 && errors[names[0]]) {
      fieldError = errors[names[0]][names[1]];
    }
  }

  let fieldValue = field.value;
  if (fieldValue && props.valueDateFormat) {
    fieldValue = moment(new Date(fieldValue)).format(props.valueDateFormat);
  }

  return (
    <>
      <DatePicker
        className={className}
        {...field}
        {...props}
        selected={(fieldValue && new Date(fieldValue)) || null}
        customInput={<ExampleCustomInput />}
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={(val) => {
          let value = val;
          if (value && props.valueDateFormat) {
            value = moment(new Date(value)).format(props.valueDateFormat);
          }
          console.log("date selected", value);

          setFieldValue(field.name, value);
        }}
      />

      {(fieldError || serverError) && (
        <ErrorMessage>{fieldError || serverError}</ErrorMessage>
      )}
    </>
  );
};

export default ZDatePicker;
