import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { actionTypes as authActionTypes } from "../_redux/authRedux";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
  localToUSCentralToUTC,
  localToUSCentralToUTCFormattedDate,
} from "../../services/Utilities";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  MANAGE_PAYMENTS_LIST,
  SELLER_AUCTION_ITEM_LIST_URL,
} from "../../Constants";

import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import { fetchData } from "../../services/ManagePaymentService";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import ZDatePicker from "../../components/form/ZDatePicker";
import { Field, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";

const AuctionItemList = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();
  const [values, setValues] = useState([]);

  const filterInitialValues = {
    contactNumber: "",
    paymentDate: "",
    sellerId: "",
  };

  const { miscData } = useSelector(
    ({ auth }) => ({
      miscData: auth.miscData,
    }),
    shallowEqual
  );

  const fetchMyItems = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      let searchParams = Object.keys(values)
        .map((key) => key + "=" + values[key])
        .join("&");
      searchParams = pagingParams ? "&" + searchParams : searchParams;

      const rs = await fetchData(
        MANAGE_PAYMENTS_LIST + "?" + pagingParams + searchParams
      );
      console.log("items", rs);

      const content = rs?.content;
      const items = [];
      content?.map((c) => {
        let m = {
          ...c,
          startedOn: c.startedOn
            ? formattedUTCtoUSCentral(c.startedOn)
            : c.startedOn,
          endingIn: c.endingIn
            ? formattedUTCtoUSCentral(c.endingIn)
            : c.endingIn,
          paymentDate: c.paymentDate
            ? formattedUTCtoUSCentral(c.paymentDate)
            : c.paymentDate,
        };

        items.push(m);
      });

      setItemList(items);

      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchMyItems();
  }, []);

  useEffect(() => {
    fetchMyItems();
  }, [values]);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchMyItems();
  }, [pageSize, currentPage]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                sellerManagementActive
                subMenu={"manage-seller-payments"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              <PlaceholderHeading title="Manage Seller Payments"></PlaceholderHeading>
              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={filterInitialValues}
                onSubmit={(values) => {
                  const submitted = {
                    ...values,
                    // fromDate: values?.fromDate
                    //   ? moment(new Date(values?.fromDate)).format("YYYY-MM-DD")
                    //   : "",
                    paymentDate: values?.paymentDate
                      ? localToUSCentralToUTCFormattedDate(values.paymentDate)
                      : "",
                  };

                  const valArr = [];
                  for (const [key, value] of Object.entries(submitted)) {
                    valArr[key] = value;
                  }

                  setValues(valArr);
                }}
              >
                {({
                  handleSubmit,
                  resetForm,
                  errors,
                  setFieldValue,
                  validateForm,
                }) => (
                  <form>
                    <div className="row mt-4">
                      <div className="col mb-2">
                        <Field
                          className="form-control"
                          name="sellerId"
                          component={ZInputComponent}
                          placeholder="Seller Id"
                        />
                      </div>
                      <div className="col mb-2">
                        <Field
                          className="form-control"
                          name="contactNumber"
                          component={ZInputComponent}
                          placeholder="Contact Number"
                        />
                      </div>
                      <div className="col mb-2">
                        <ZDatePicker
                          dateFormat="MM/dd/yyyy"
                          errors={errors}
                          placeholderText="Payment Date"
                          maxDate={new Date()}
                          name="paymentDate"
                        />
                      </div>

                      <div className="col-auto mb-2 text-right">
                        <div className="js-form-message mb-2">
                          <button
                            className="btn btn-secondary px-5 mr-2"
                            type="button"
                            onClick={async (e) => {
                              e.preventDefault();
                              resetForm();
                              setValues([]);
                            }}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-primary-dark-w px-5"
                            type="submit"
                            onClick={async (e) => {
                              e.preventDefault();
                              let res = await validateForm();
                              console.log("errors", res);
                              if (res && Object.values(res)?.length > 0) {
                              }
                              handleSubmit(e);
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>

              <div className="row">
                <div className="col-12 mt-3">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <AuctionItemList.TableBodyRow
                          key={i}
                          itemId={item.itemId}
                          auctionType={item.type}
                          image={item.defaultImage}
                          title={item.title}
                          lot={item.lot}
                          listingDate={item.createdOn}
                          startDate={item.startedOn}
                          endDate={item.endingIn}
                          paymentAmount={item.winningAmount}
                          currency={item.currency}
                          paymentDate={item.paymentDate}
                          sellerId={item.sellerId}
                          sellerName={item.sellerName}
                          sellerContact={item.sellerContactNumber}
                          paymentConfirmClicked={(itemId) => {
                            navigate("/confirm-payment/" + itemId);
                          }}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <AuctionItemList.MobileBodyRow
                          key={i}
                          itemId={item.itemId}
                          auctionType={item.type}
                          image={item.defaultImage}
                          title={item.title}
                          lot={item.lot}
                          listingDate={item.createdOn}
                          startDate={item.startedOn}
                          endDate={item.endingIn}
                          paymentAmount={item.winningAmount}
                          currency={item.currency}
                          paymentDate={item.paymentDate}
                          sellerId={item.sellerId}
                          sellerName={item.sellerName}
                          sellerContact={item.sellerContactNumber}
                          paymentConfirmClicked={(itemId) => {
                            navigate("/confirm-payment/" + itemId);
                          }}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

AuctionItemList.TableBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  currency,
  paymentAmount,
  paymentDate,
  sellerId,
  sellerName,
  sellerContact,
  paymentConfirmClicked = (itemId) => {},
}) => {
  return (
    <>
      <tr>
        <td>
          {image && (
            <div
              className={tableClasses.imgContainer}
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </td>

        <td>
          <div className={`${tableClasses.label}`}>
            <span className="text-capitalize">
              {auctionType.replaceAll("_", " ").toLowerCase()}
            </span>
          </div>
          <div className={tableClasses.heading}>{title}</div>
          <div className="mt-2">
            <span className={tableClasses.label}>Listing ID: </span>
            <span className={tableClasses.value}> {lot}</span>
          </div>
          <div>
            <span className={tableClasses.label}>Dated: </span>
            <span className={tableClasses.value}> {listingDate}</span>
          </div>
        </td>

        <td>
          <div>
            <span className={tableClasses.label}>Seller ID: </span>
            <span className={tableClasses.value}> {sellerId}</span>
          </div>

          <div className={tableClasses.label}>Seller Name</div>
          <div className={tableClasses.value}> {sellerName}</div>

          <div className={tableClasses.label}>Seller Contact </div>
          <div className={tableClasses.value}> {sellerContact}</div>
        </td>

        <td>
          <div className={tableClasses.label}>Start Date</div>
          <div className={tableClasses.value}>{startDate}</div>
          <div className={tableClasses.label}>Ending Date</div>
          <div className={tableClasses.value}>{endDate}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Payment Amount</div>
          <div className={tableClasses.value}>{formatUSD(paymentAmount)}</div>
          <div className={tableClasses.label}>Payment Date</div>
          <div className={tableClasses.value}>{paymentDate}</div>
        </td>

        <td>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              paymentConfirmClicked(itemId);
            }}
            className="btn btn-block py-1 btn-primary-dark-w"
          >
            CONFIRM PAYMENT
          </a>
        </td>
      </tr>
    </>
  );
};

AuctionItemList.MobileBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  currency,
  paymentAmount,
  paymentDate,
  sellerId,
  sellerName,
  sellerContact,
  paymentConfirmClicked = (itemId) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={`${tableClasses.block} ${tableClasses.buttons}`}>
        <div className={tableClasses.buttonRow}>
          <div className={tableClasses.value}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                paymentConfirmClicked(itemId);
              }}
              className={tableClasses.link}
            >
              CONFIRM PAYMENT
            </a>
          </div>
        </div>
      </div>

      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <span className="text-capitalize">
                {auctionType.replaceAll("_", " ").toLowerCase()}
              </span>
            </div>
            <div className={tableClasses.heading}>{title}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing ID</div>
            <div className={tableClasses.value}>{lot}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Seller ID</div>
            <div className={tableClasses.value}>{sellerId}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Seller Name</div>
            <div className={tableClasses.value}>{sellerName}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Seller Contact</div>
            <div className={tableClasses.value}>{sellerContact}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing Date</div>
            <div className={tableClasses.value}>{listingDate}</div>
          </div>
          {startDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Start Date</div>
              <div className={tableClasses.value}>{startDate}</div>
            </div>
          )}
          {endDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Ending Date</div>
              <div className={tableClasses.value}>{endDate}</div>
            </div>
          )}

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Amount</div>
            <div className={tableClasses.value}>{formatUSD(paymentAmount)}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Date</div>
            <div className={tableClasses.value}>{paymentDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionItemList;
