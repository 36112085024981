import { useState } from "react";
import AccountMenuItem from "./AccountMenuItem";
import "./AccountMenu.css";
import { useNavigate, Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { hasAdminRole } from "../../services/Utilities";

const AccountMenu = ({
  configActive,
  sellerManagementActive,
  bidderManagementActive,
  demoActive,
  reportsActive,
  userManagementActive,
  contactUsActive,
  probationConfigActive,
  subMenu,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clicked, setClicked] = useState("0");

  const { user, roles } = useSelector(
    ({ auth }) => ({
      user: auth.user,
      roles: auth.roles,
    }),
    shallowEqual
  );

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <ul className="accordion">
      <AccountMenuItem
        single
        title={"Dashboard"}
        onToggle={() => {
          handleToggle(1);
          navigate("/dashboard");
        }}
        active={clicked === 1}
        key={1}
      />

      {hasAdminRole(roles, "USER_MANAGEMENT") && (
        <AccountMenuItem
          single
          title={"Admin User Management"}
          onToggle={() => {
            handleToggle(0);
            navigate("/users");
          }}
          active={clicked === 0 || userManagementActive}
          key={0}
        />
      )}

      {hasAdminRole(roles, "SELLER_MANAGEMENT") && (
        <AccountMenuItem
          title={"Seller Management"}
          onToggle={() => {
            handleToggle(2);
          }}
          active={clicked === 2 || sellerManagementActive}
          key={2}
        >
          <ul className="innerpanel-links">
            <li>
              <Link
                className={
                  subMenu === "seller-account-approval" ? "active" : ""
                }
                to="/seller-approval-requests"
              >
                Seller Account Approval
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "registered-sellers" ? "active" : ""}
                to="/registered-sellers"
              >
                Registered Sellers
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "seller-demo-requests" ? "active" : ""}
                to="/demo-requests"
              >
                Seller Demo Requests
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "seller-activity-report" ? "active" : ""}
                to="/seller-activity-report"
              >
                Seller Activity Report
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "manage-seller-payments" ? "active" : ""}
                to="/manage-payments"
              >
                Manage Seller Payments
              </Link>
            </li>
          </ul>
        </AccountMenuItem>
      )}

      {hasAdminRole(roles, "BIDDER_MANAGEMENT") && (
        <AccountMenuItem
          title={"Bidder Management"}
          onToggle={() => {
            handleToggle(3);
          }}
          active={clicked === 3 || bidderManagementActive}
          key={3}
        >
          <ul className="innerpanel-links">
            <li>
              <Link
                className={subMenu === "identity-verification-request" ? "active" : ""}
                to="/identity-verification-requests"
              >
                Bidder Identity Verification
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "registered-bidders" ? "active" : ""}
                to="/registered-bidders"
              >
                Registered Bidders
              </Link>
            </li>
          </ul>
        </AccountMenuItem>
      )}

      {hasAdminRole(roles, "CONFIGURATION_MANAGEMENT") && (
        <AccountMenuItem
          single={true}
          title={"Configuration Management"}
          onToggle={() => {
            handleToggle(5);
            navigate("/configurations");
          }}
          active={clicked === 5 || configActive}
          key={5}
        />
      )}

      {hasAdminRole(roles, "REPORTS") && (
        <AccountMenuItem
          title={"Reports"}
          onToggle={() => {
            handleToggle(6);
          }}
          active={clicked === 6 || reportsActive}
          key={6}
        >
          <ul className="innerpanel-links">
            <li>
              <Link
                className={subMenu === "auction-listing" ? "active" : ""}
                to="/auction-listing-report"
              >
                Auction Listing Report
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "sales-report" ? "active" : ""}
                to="/sales-report"
              >
                Sales Report
              </Link>
            </li>
          </ul>
        </AccountMenuItem>
      )}

      {hasAdminRole(roles, "CONTACT_US_MANAGEMENT") && (
        <AccountMenuItem
          single
          title={"Contact Us Request"}
          onToggle={() => {
            handleToggle(7);
            navigate("/contact-us-request");
          }}
          active={clicked === 7 || contactUsActive}
          key={7}
        />
      )}
      {/* <AccountMenuItem
        single
        title={"Bidder Probation Configurations"}
        onToggle={() => {
          handleToggle(8);
          navigate("/probation-configurations");
        }}
        active={clicked === 8 || probationConfigActive}
        key={8}
      /> */}
    </ul>
  );
};

export default AccountMenu;
