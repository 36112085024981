import React from "react";
import classes from "./ViewField.module.css";

const DownloadFileField = ({ label, value, link }) => {
  return (
    <div className={classes.view}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>
        <a href={link} target="_blank" download>
          {value ?? " "}
        </a>
      </div>
    </div>
  );
};

export default DownloadFileField;
