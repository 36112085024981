import {
  DATETIME_SHOW_FORMAT,
  DATE_SHOW_FORMAT,
  TIMEZONE_NAME,
  genericTableErrorMsg,
} from "../Constants";
import moment from "moment-timezone";

export function changeRoute(path) {
  const { CustomEvent } = window;
  const event = new CustomEvent("changeroute", { detail: path });
  window.dispatchEvent(event);
}

export const deflateErrors = (serverErrors) => {
  if (serverErrors && serverErrors.length > 0) {
    let resp = {};

    serverErrors.forEach((error) => {
      var keyNames = Object.keys(error);
      resp = {
        ...resp,
        [keyNames[0] === "field" ? error[keyNames[0]] : error[keyNames[1]]]:
          keyNames[0] === "field" ? error[keyNames[1]] : error[keyNames[0]],
      };
    });
    return resp;
  } else {
    return {};
  }
};

export const deflateTableErrors = (tableErrors) => {
  if (tableErrors && tableErrors.length > 0) {
    let resp = {};

    tableErrors.forEach((error) => {
      var keyNames = Object.keys(error);
      resp = {
        ...resp,
        [keyNames[0] === "form" ? error[keyNames[0]] : error[keyNames[1]]]:
          keyNames[0] === "form" ? error[keyNames[1]] : error[keyNames[0]],
      };
    });
    return resp;
  } else {
    return {};
  }
};

export const validatePassword = (value) => {
  let error;
  if (!value) {
    error = "Required";
  } else if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g.test(
      value
    )
  ) {
    error = "Password does not comply to password policy";
  }
  return error;
};

export const validateUSPhoneNumber = (value) => {
  let error;
  if (value) {
    if (!/^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/g.test(value)) {
      error = "Please provide valid phone number";
    }
  }
  return error;
};

export const validateUsername = (value) => {
  let error;

  if (!value) {
    error = "Username is required";
  } else if (/[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/g.test(value)) {
    error = "Please provide valid username";
  } else if (!/[A-Za-z0-9_]/g.test(value)) {
    error = "Please provide valid username";
  }
  return error;
};

export const isJSON = (jsonString) => {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }

  return true;
};

export const isBadRequest = (jsonString) => {
  try {
    const json = JSON.parse(jsonString);
    if (json?.status === "BAD_REQUEST") {
      return true;
    }
  } catch (e) {}

  return false;
};

export const isSessionExpired = (jsonString) => {
  try {
    const json = JSON.parse(jsonString);
    if (json?.status === "SESSION_EXPIRED") {
      return true;
    }
  } catch (e) {}

  return false;
};

export const isUserAuthorized = (jsonString) => {
  try {
    const json = JSON.parse(jsonString);
    if (json?.status === "FORBIDDEN") {
      return true;
    }
  } catch (e) {}

  return false;
};

export const isCyberAttack = (jsonString) => {
  try {
    const json = JSON.parse(jsonString);
    if (json?.status === "CYBER_ATTACK") {
      return true;
    }
  } catch (e) {}

  return false;
};

export const isStopFlowConditionMet = (jsonString) => {
  if (isSessionExpired(jsonString)) return true;
  if (isUserAuthorized(jsonString)) return true;
  if (isCyberAttack(jsonString)) return true;
  return false;
};

export const clearTabsErrors = (wizard) => {
  if (wizard && wizard?.tabs) {
    for (const [key, value] of Object.entries(wizard?.tabs)) {
      value.serverErrors = {};
    }
  }
  return wizard;
};

export const prepareWizardTabs = (wizard) => {
  if (wizard && wizard?.tabs) {
    for (const [key, value] of Object.entries(wizard?.tabs)) {
      if (wizard?.status?.currentStep === value?.step) {
        value.active = true;
      } else {
        value.active = false;
      }

      if (wizard?.status?.completedSteps >= value?.step) {
        value.completed = true;
      }

      if (wizard?.status?.completedSteps + 1 >= value?.step) {
        value.tabDisable = false;
      }
    }
  }
  return wizard;
};

export const setTabErrors = (wizard, tab, errors) => {
  if (wizard && wizard?.tabs) {
    for (const [key, value] of Object.entries(wizard?.tabs)) {
      if (tab === value?.step) {
        console.log("selected tab", value);
        value.active = true;
        value.serverErrors = errors;
      } else {
        value.active = false;
      }
    }
  }
  return wizard;
};

export const setTableErrors = (wizard, tab, errors, tableErrors) => {
  if (wizard && wizard?.tabs) {
    for (const [key, value] of Object.entries(wizard?.tabs)) {
      if (tab === value?.step) {
        console.log("selected tab", value);
        value.active = true;
        value.serverErrors = errors;
        value.tableErrors = tableErrors;
      } else {
        value.active = false;
      }
    }
  }
  return wizard;
};

export const getTableSectionErrors = (data) => {
  let errors = {};

  if (data) {
    data.forEach((error) => {
      var keyNames = Object.keys(error);
      errors = {
        ...errors,
        [keyNames[0] === "form" ? error[keyNames[0]] : error[keyNames[1]]]:
          genericTableErrorMsg,
      };
    });
  }

  return errors;
};

export const markTabActiveStatus = (wizard, step, tabActive) => {
  if (wizard && wizard?.tabs) {
    for (const [key, value] of Object.entries(wizard?.tabs)) {
      if (step === value?.step) {
        value.active = tabActive;
      }
    }
  }

  return wizard;
};

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getCountryNameByCode = (countries, code) => {
  return countries?.filter((country) => country.key === code)[0]?.value;
};

export const getValueByKey = (lookup, key) => {
  return lookup?.filter((item) => item.key == key)[0]?.value;
};

export const getValueTwoByKey = (lookup, key) => {
  return lookup?.filter((item) => item.key == key)[0]?.valueTwo;
};

export const extractPhotoValue = (photoValue) => {
  return photoValue?.split(",")?.length == 2
    ? photoValue?.split(",")[1]
    : photoValue;
};

export const scrollTo = (id, completedCallback) => {
  const element = document.getElementById(id);
  if (!element) {
    if (completedCallback) completedCallback();
    return;
  }

  let elementOffset = element?.offsetTop - 80 ?? 0;
  elementOffset = elementOffset > 0 ? elementOffset : 0;

  window.scrollToPosition(elementOffset, () => {
    if (completedCallback) completedCallback();
  });
};

export const scrollToPosition = (position) => {
  window?.scrollTo({
    top: position,
    behavior: "smooth",
  });
};

export const tabScrollTo = (tab, completedCallback) => {
  // when wizard is rendered
  // open tab
  // get offset of the tab
  // scroll to the tab

  setTimeout(function () {
    window.slideDisplay("tab" + tab, true, () => {
      setTimeout(function () {
        const tabElement = document.getElementById("tabHead" + tab);
        if (!tabElement) {
          if (completedCallback) completedCallback();
          return;
        }

        let tabElementOffset = tabElement?.offsetTop - 80 ?? 0;
        tabElementOffset = tabElementOffset > 0 ? tabElementOffset : 0;

        window.scrollToPosition(tabElementOffset, () => {
          window.removeExtraData("htmldatatab");
          if (completedCallback) completedCallback();
        });
      }, 200);
    });
  }, 200);
};

export const tabSaved = (completedCallback) => {
  scrollToPosition(0);
  if (completedCallback) completedCallback();
};

export const tabValidationFailed = (tab, completedCallback) => {
  // validation failed || not found
  // set generic error message to be shown on top of the tab
  // get offset of current tab
  // scroll to top of current tab
  // scrollTo(tab, completedCallback);
  scrollToPosition(0);
  if (completedCallback) completedCallback();
};

export const wizardValidationFailed = (
  currentTab,
  validationFailedTab,
  completedCallback
) => {
  // wizard validation failed || business logic failed
  // get tab from server response
  // get current tab response
  // set generic error message to be shown on top of the tab
  // if current tab != tab from server response
  // then
  // open tab from server response
  // get offset of tab
  // scroll to the top of tab
  // else
  // get offset of tab
  // scroll to the top of tab

  if (currentTab !== validationFailedTab) {
    window.slideDisplay("tab" + validationFailedTab, true, () => {
      scrollTo(validationFailedTab, completedCallback);
    });
  } else {
    scrollTo(currentTab, completedCallback);
  }
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return (
      Math.floor(interval) +
      (Math.floor(interval) === 1 ? " year" : " years") +
      " ago"
    );
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return (
      Math.floor(interval) +
      (Math.floor(interval) === 1 ? " month" : " month") +
      " ago"
    );
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return (
      Math.floor(interval) +
      (Math.floor(interval) === 1 ? " day" : " days") +
      " ago"
    );
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return (
      Math.floor(interval) +
      (Math.floor(interval) === 1 ? " hour" : " hours") +
      " ago"
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) +
      (Math.floor(interval) === 1 ? " minute" : " minutes") +
      " ago"
    );
  }
  return Math.floor(seconds) + " seconds ago";
};

export const differenceWithCurrentDate = (date) => {
  let today = new Date().toISOString().slice(0, 10);
  const startDate = date;
  const endDate = today;
  const diffInMs = new Date(endDate) - new Date(startDate);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  return diffInDays;
};

export const prepareServerFile = async (file, blob) => {
  return new Promise((resolve, _) => {
    var reader = new FileReader();
    reader.onloadend = () => resolve({ ...file, fileData: reader.result });
    reader.readAsDataURL(blob);
  });
};

export const populateUploadedFileAttributes = (list) => {
  if (list && list.length > 0) {
    list.forEach((file) => {
      file.documentDataList.forEach((page) => {
        page.viewable = true;
        page.removable = false;
      });
    });
  }
  return list;
};

export const preventWrongDates = (date) => {
  let tempDate = date;

  if (tempDate && new Date(tempDate).getFullYear() < 1000) {
    return moment().format("YYYY-MM-DD");
  } else {
    return tempDate;
  }
};

export const preventFutureDates = (date) => {
  let tempDate = date;

  if (
    tempDate &&
    (new Date(tempDate) > new Date() || new Date(tempDate).getFullYear() < 1000)
  ) {
    return moment().format("YYYY-MM-DD");
  } else {
    return tempDate;
  }
};

export const Regex = {
  stringWithSpace: "^(?!\\s)[a-zA-Z\\s]*$",
  numeric: "^(?!\\s)[0-9]+$",
  phoneNumber: "^(?!\\s)\\+?[0-9]+$",
  alphaNumeric: "^(?!\\s)[a-zA-Z-0-9]*$",
  stringWithoutLeadingSpace: "^(?!\\s)[a-zA-Z-0-9\\s]*$",
  alphaNumericWithSpaceTrimmed: "^(?!\\s)([a-zA-Z-0-9]*[\\s]*[a-zA-Z-0-9]+)*$",
  stringWithSpaceTrimmed: "^(?!\\s)([a-zA-Z]*[\\s]*[a-zA-Z]+)*$",
};

export const validateStringWithDap = (value) => {
  let error;
  if (value) {
    if (!/^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/g.test(value)) {
      error = "Please provide valid phone number";
    }
  }
  return error;
};

export const trimAllTextFields = (dataObject) => {
  Object.keys(dataObject).forEach((attribute) => {
    if (typeof dataObject[attribute] == "object") {
      dataObject[attribute] = trimAllTextFields(dataObject[attribute]);
    } else {
      dataObject[attribute] = dataObject[attribute].toString().trim();
    }
  });

  return dataObject;
};

export const formatUSD = (amount) => {
  if (!amount && amount != 0) return "";

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const youtubeVideoThumbnail = (URL) => {
  const youtubeVideoId = URL?.match(
    /youtube\.com.*(\?v=|\/embed\/)(.{11})/
  )?.pop();

  if (youtubeVideoId?.length == 11) {
    return "//img.youtube.com/vi/" + youtubeVideoId + "/0.jpg";
  }

  return undefined;
};

export const isSeller = (type) => {
  return type === "SELLER";
};

export const localToUSCentralMoment = (date, convertLocal = true) => {
  let convertedDate = moment(new Date(date));
  convertedDate.tz(TIMEZONE_NAME, convertLocal);

  console.log("converted date", convertedDate.format());

  return convertedDate;
};

export const localToUSCentralToUTC = (date) => {
  let convertedDate = moment(new Date(date).toISOString());
  convertedDate.tz(TIMEZONE_NAME, true);
  return convertedDate.toISOString();
};

export const localToUSCentralToUTCFormattedDate = (date) => {
  let convertedDate = moment(new Date(date).toISOString());
  convertedDate.tz(TIMEZONE_NAME, true);
  return convertedDate.format("YYYY-MM-DD");
};

export const formattedUTCtoUSCentral = (date) => {
  if (!date) return;
  let convertedDate = moment(date);
  convertedDate.utc(true);
  convertedDate.tz(TIMEZONE_NAME, false);
  return convertedDate.format(DATETIME_SHOW_FORMAT);
};

export const formattedUTCDatetoUSCentral = (date) => {
  if (!date) return;
  let convertedDate = moment(date);
  convertedDate.utc(true);
  convertedDate.tz(TIMEZONE_NAME, false);
  return convertedDate.format(DATE_SHOW_FORMAT);
};

export const hasAdminRole = (roles, code) => {
  return roles?.includes(code);
};
