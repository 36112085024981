import { shallowEqual, useSelector } from "react-redux";
import TopBarMyAccountMenu from "./TopBarMyAccountMenu";
import NotificationBar from "./notification/NotificationBar";

const TopBar = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
    }),
    shallowEqual
  );

  return (
    <>
      {isAuthorized && (
        <div className="u-header-topbar py-2 d-none d-xl-block">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="topbar-left"></div>

              <div className="topbar-right ml-auto">
                <ul className="list-inline mb-0">
                  <>
                    <TopBarMyAccountMenu />

                    <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                      {window.screen.width >= 1200 && <NotificationBar />}
                    </li>
                  </>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {isAuthorized && (
        <div className="u-header-topbar py-2 d-none d-md-block d-xl-none">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="topbar-right ml-auto">
                <ul className="list-inline mb-0">
                  <TopBarMyAccountMenu />

                  <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                    {window.screen.width >= 768 &&
                      window.screen.width <= 1199 && <NotificationBar />}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopBar;
