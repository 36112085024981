import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import AccountMenu from "../components/AccountMenu/AccountMenu";

const Main = () => {
  return (
    <>
      <Layout>
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu />
            </div>
            <div className="col-lg-9 col-md-8 mt-5"></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Main;
