import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import { useAuthContext } from "../../redux/auth-context";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import {
  DASHBOARD_STATS_SALES_DAY_URL,
  DASHBOARD_STATS_SALES_INTERVAL_URL,
  DASHBOARD_STATS_SALES_STATUS_URL,
  DASHBOARD_STATS_STATUS_URL,
  DASHBOARD_STATS_SELLER_MGT_URL,
  DASHBOARD_STATS_BIDDER_MGT_URL,
  DASHBOARD_STATS_OTHER_URL
} from "../../Constants";
import { fetchRecords } from "../../services/DashboardService";
import DashboardCard from "./DashboardCard";
import CardHeading from "./CardHeading";
import ListingInfoBlocks from "./ListingInfoBlocks";
import ListingInfoBlock from "./ListingInfoBlock";
import LabelValue from "./LabelValue";
import DetailLinkDashboardCard from "./DetailLinkDashboardCard";
import DetailedLink from "./DetailedLink";
import ListItem from "./ListItem";
import ListCard from "./ListCard";
import LastDaysSaleBarChart from "./LastDaysSaleBarChart";

const Dashboard = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();

  const [statusStats, setStatusStats] = useState([]);
  const [sellerMgtStats, setSellerMgtStats] = useState([]);
  const [bidderMgtStats, setBidderMgtStats] = useState([]);
  const [salesIntervalStats, setSalesIntervalStats] = useState([]);
  const [salesStatusStats, setSalesStatusStats] = useState([]);
  const [salesDayStats, setSalesDayStats] = useState([]);
  const [overAllSales, setOverAllSales] = useState(0);
  const [otherStats, setOtherStats] = useState(0);


  const getValueOrZero = (key, arr) => {
    return arr[key] ?? 0;
  };

  const fetchDashboardData = async () => {
    try {
      const response = await Promise.all([
        fetchRecords(DASHBOARD_STATS_STATUS_URL),
        fetchRecords(DASHBOARD_STATS_SALES_INTERVAL_URL),
        fetchRecords(DASHBOARD_STATS_SALES_STATUS_URL),
        fetchRecords(DASHBOARD_STATS_SALES_DAY_URL),
        fetchRecords(DASHBOARD_STATS_SELLER_MGT_URL),
        fetchRecords(DASHBOARD_STATS_BIDDER_MGT_URL),
        fetchRecords(DASHBOARD_STATS_OTHER_URL)
      ]);

      const itemStatusesCount = [];
      for (const [key, value] of Object.entries(response[0])) {
        itemStatusesCount[value.key] = value.valueNumber;
      }
      setStatusStats(itemStatusesCount);

      setSalesIntervalStats(response[1]);

      let sum = response[1].reduce((accumulator, object) => {
        return accumulator + object.valueDouble;
      }, 0);
      setOverAllSales(sum);

      const salesStatuses = [];
      for (const [key, value] of Object.entries(response[2])) {
        salesStatuses[value.key] = value.valueDouble;
      }
      setSalesStatusStats(salesStatuses);

      const daySalesCounts = [];
      for (const [key, value] of Object.entries(response[3])) {
        daySalesCounts[value.key] = value.valueDouble;
      }
      setSalesDayStats(daySalesCounts);

      setSellerMgtStats(response[4]);
      setBidderMgtStats(response[5]);
      setOtherStats(response[6]);
    } catch (e) {
      console.log("error", e);
      if (isStopFlowConditionMet(e.message)) return;
      // navigate("/error");
    }
  };

  useEffect(() => {
    async function fetchData() {
      ctx.showProgress(true);
      await fetchDashboardData();
      ctx.showProgress(false);
    }

    fetchData();

    const updateInterval = setInterval(fetchDashboardData, 60000);

    return () => {
      if (updateInterval) clearInterval(updateInterval);
    };
  }, []);

  return (
    <>
      <Layout grey>
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading title="Dashboard" headingSize="sm">
                <Link to="/main" className="font-size-16">
                  Back to Main Menu
                </Link>
              </PlaceholderHeading>

              <div className="row">
                <div className="col-lg-4 col-12">
                  <DetailLinkDashboardCard
                    height="608"
                    card={
                      <DashboardCard>
                        <CardHeading heading="Seller Management" />

                        <ListingInfoBlocks>
                          <ListingInfoBlock
                              label="Demo Requests"
                              value={
                                <div className="p-2 px-2 py-1 font-size-14 d-block">
                                  <Link
                                    style={{ color: "", fontSize: "18px" }}
                                    to={"/demo-requests"}
                                  >
                                    {sellerMgtStats.demoRequests}
                                  </Link>
                                </div>
                              }
                            ></ListingInfoBlock>

                            <ListingInfoBlock
                              label="Pending Account Approval"
                              value={
                                <div className="p-2 px-2 py-1 font-size-14 d-block">
                                  <Link
                                    style={{ color: "", fontSize: "18px" }}
                                    to={"/seller-approval-requests"}
                                  >
                                    {sellerMgtStats.pendingAccountApproval}
                                  </Link>
                                </div>
                              }
                            ></ListingInfoBlock>

                            <ListingInfoBlock
                              label="Manage Seller Payments"
                              value={
                                <div className="p-2 px-2 py-1 font-size-14 d-block">
                                  <Link
                                    style={{ color: "", fontSize: "18px" }}
                                    to={"/manage-payments"}
                                  >
                                    {sellerMgtStats.manageSellerPayments}
                                  </Link>
                                </div>
                              }
                            ></ListingInfoBlock>

                            
                            <ListingInfoBlock
                              label="Pending Results Acknowledgment"
                              value={
                                <div className="p-2 px-2 py-1 font-size-20 d-block">
                                  {sellerMgtStats.pendingResultsAcknowledgment}
                                </div>
                              }
                            ></ListingInfoBlock>

                            <ListingInfoBlock
                              label="Pending Payment Confirmation"
                              value={
                                <div className="p-2 px-2 py-1 font-size-20 d-block">
                                  {sellerMgtStats.pendingPaymentConfirmation}
                                </div>
                              }
                            ></ListingInfoBlock>

                            <ListingInfoBlock
                              label="Pending Invoice Generation"
                              value={
                                <div className="p-2 px-2 py-1 font-size-20 d-block">
                                  {sellerMgtStats.pendingInvoicegeneration}
                                </div>
                              }
                            ></ListingInfoBlock>

                            
                            <ListingInfoBlock
                              label="Pending Item Pickup Confirmation"
                              value={
                                <div className="p-2 px-2 py-1 font-size-20 d-block">
                                  {sellerMgtStats.pendingItemPickupConfirmation}
                                </div>
                              }
                            ></ListingInfoBlock>


                        </ListingInfoBlocks>
                      </DashboardCard>
                    }
                  ></DetailLinkDashboardCard>
                </div>
                <div className="col-lg-4 col-12">
                  <DetailLinkDashboardCard
                    height="608"
                    card={
                      <DashboardCard>
                        <CardHeading heading="Bidder Management" />

                        <ListingInfoBlocks>
                          <ListingInfoBlock
                              label="ID Verification"
                              value={
                                <div className="p-2 px-2 py-1 font-size-14 d-block">
                                  <Link
                                    style={{ color: "", fontSize: "18px" }}
                                    to={"/identity-verification-requests"}
                                  >
                                    {bidderMgtStats.idverification}
                                  </Link>
                                </div>
                              }
                            ></ListingInfoBlock>

                            <ListingInfoBlock
                              label="Accounts Created - Pending ID Upload"
                              value={
                                <div className="p-2 px-2 py-1 font-size-20 d-block">
                                  {bidderMgtStats.pendingIDUpload}
                                </div>
                              }
                            ></ListingInfoBlock>

                            <ListingInfoBlock
                              label="Accounts Created - Pending Credit Card"
                              value={
                                <div className="p-2 px-2 py-1 font-size-20 d-block">
                                  {bidderMgtStats.pendingCreditCard}
                                </div>
                              }
                            ></ListingInfoBlock>

                            
                            <ListingInfoBlock
                              label="Auction Winners  - Pending Payment"
                              value={
                                <div className="p-2 px-2 py-1 font-size-20 d-block">
                                  {bidderMgtStats.pendingPayment}
                                </div>
                              }
                            ></ListingInfoBlock>

                        </ListingInfoBlocks>
                      </DashboardCard>
                    }
                  ></DetailLinkDashboardCard>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <DashboardCard height="130">
                        <LabelValue
                          label="Active"
                          value={getValueOrZero("ACTIVE", statusStats)}
                        />
                      </DashboardCard>
                    </div>
                    <div className="col-lg-4 col-12">
                      <DashboardCard height="130">
                        <LabelValue
                          label="Sold"
                          value={getValueOrZero(
                            "SUCCESSFUL_ENDED",
                            statusStats
                          )}
                        />
                      </DashboardCard>
                    </div>
                    <div className="col-lg-4 col-12">
                      <DashboardCard height="130">
                        <LabelValue
                          label="Unsold"
                          value={getValueOrZero("UNSUCCESSFUL_ENDED", statusStats)}
                        />
                      </DashboardCard>
                    </div>
                  </div>

                  <div className="mt-5">
                    <DashboardCard bottomPadding={false} topPadding={false}>
                      <ListCard>
                        <ListItem
                          label="Total Sales"
                          value={formatUSD(overAllSales)}
                        />
                      </ListCard>
                    </DashboardCard>
                  </div>

                  <div className="mt-5">
                    <DashboardCard bottomPadding={false} topPadding={false}>
                      <ListCard>
                        <ListItem
                          label="Bidder Fee Collected"
                          value={formatUSD(otherStats.buyerFeeCollected)}
                        />
                      </ListCard>
                    </DashboardCard>
                  </div>

                  <div className="mt-5">
                    <DashboardCard bottomPadding={false}>
                    <CardHeading heading="Bidly Auction Configurations" />
                      <ListCard>
                        <ListItem
                          label="Contact Info"
                          value={otherStats.contactInfo}
                        />
                        <ListItem
                          label="Bank Account"
                          value={otherStats.bankAccount}
                        />
                      </ListCard>
                    </DashboardCard>
                  </div>

                  <div className="mt-5">
                    <DashboardCard bottomPadding={false} topPadding={false}>
                      <ListCard>
                        <ListItem
                          label="Contact Us Messages"
                          value={otherStats.contactUsMessages}
                        />
                      </ListCard>
                    </DashboardCard>
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-5"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
