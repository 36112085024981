import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import tableClasses from "../../components/table/ListingTable.module.css";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import { searchAdminUsers } from "../../services/AdmminDashboardServices";
import { useEffect } from "react";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import ListingTable from "../../components/table/ListingTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionTypes as authActionTypes } from "../_redux/authRedux";
import { Pagination } from "react-bootstrap";
import { PaginationComponent } from "../search/PaginationComponent";
import { ADMIN_ALL_USER } from "../../Constants";

const AdminUsers = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const dispatch = useDispatch();

  const { miscData } = useSelector(
    ({ auth }) => ({
      miscData: auth.miscData,
    }),
    shallowEqual
  );

  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const fetchMyItems = () => {
    ctx.showProgress(true);

    const pagingParams =
      currentPage + 1 && pageSize
        ? "?" + ["page=" + currentPage, "size=" + pageSize].join("&")
        : "";

    const name = miscData?.adminUserName ?? "";

    let params = pagingParams ?? "";

    params = params ? params + (name ? "&userName=" + name : "") : "";

    let URL = ADMIN_ALL_USER + params;

    searchAdminUsers(URL)
      .then((rs) => {
        const content = rs?.content;
        setUsers(content);

        setTotal(rs.totalElements);
        setTotalPages(rs.totalPages);

        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  };

  useEffect(() => {
    fetchMyItems();
  }, []);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchMyItems();
  }, [pageSize, currentPage]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu userManagementActive />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <PlaceholderHeading title="Admin Users">
                <Link to="/add-admin-user" className="btn btn-primary-dark-w">
                  Add New User
                </Link>
              </PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row mt-4">
                <div className="col-auto mb-2">
                  <input
                    name="userName"
                    className="form-control"
                    placeholder="Admin User Name"
                    value={miscData?.adminUserName}
                    onChange={(e) => {
                      dispatch({
                        type: authActionTypes.UpdateMiscData,
                        payload: {
                          adminUserName: e.target.value,
                        },
                      });
                    }}
                  />
                </div>

                <div className="col mb-2 text-right">
                  <div className="js-form-message mb-2">
                    <button
                      className="btn btn-secondary px-5 mr-2"
                      type="button"
                      onClick={async (e) => {
                        e.preventDefault();
                        dispatch({
                          type: authActionTypes.UpdateMiscData,
                          payload: {
                            adminUserName: "",
                          },
                        });
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-primary-dark-w px-5"
                      type="submit"
                      onClick={async (e) => {
                        e.preventDefault();
                        fetchMyItems();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-3">
                  <ListingTable
                    tableBody={users?.map((item, i) => {
                      return (
                        <AdminUsers.TableBodyRow
                          key={i}
                          data={item}
                          editClicked={(userId) => {
                            navigate("/update-user/" + userId);
                          }}
                        />
                      );
                    })}
                    mobileBody={users?.map((item, i) => {
                      return (
                        <AdminUsers.MobileBodyRow
                          key={i}
                          data={item}
                          editClicked={(userId) => {
                            navigate("/update-user/" + userId);
                          }}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage + 1}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

AdminUsers.TableBodyRow = ({ data, editClicked = (userId) => {} }) => {
  return (
    <>
      <tr>
        <td>
          <div className={tableClasses.label}>Name</div>
          <div className={tableClasses.value}>
            {data?.firstName} {data?.lastName}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Username</div>
          <div className={tableClasses.value}>{data?.username}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Status</div>
          <div className={tableClasses.value}>{data?.status}</div>
        </td>

        <td>
          <a
            href="#"
            className="btn btn-block py-1 btn-primary-dark-w"
            onClick={(e) => {
              e.preventDefault();
              editClicked(data?.userId);
            }}
          >
            Edit
          </a>
        </td>
      </tr>
    </>
  );
};

AdminUsers.MobileBodyRow = ({ data, editClicked = (userId) => {} }) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={`${tableClasses.block} ${tableClasses.buttons}`}>
        <div className={tableClasses.buttonRow}>
          <div className={tableClasses.value}>
            <a
              href="#"
              className={tableClasses.link}
              onClick={(e) => {
                e.preventDefault();
                editClicked(data?.userId);
              }}
            >
              EDIT
            </a>
          </div>
        </div>
      </div>

      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.heading}>
              {data?.firstName} {data?.lastName}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Username</div>
            <div className={tableClasses.value}>{data?.username}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Status</div>
            <div className={tableClasses.value}>{data?.status}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
