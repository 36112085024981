import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  AUCTION_ITEM_SALES_REPORT_DETAILS_URL,
} from "../../Constants";
import { fetchRecords, downloadCSV } from "../../services/ReportsService";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Formik } from "formik";
import { Select } from "../../components/Select";
import ZDatePicker from "../../components/form/ZDatePicker";
import Label from "../../components/UI/Label";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import moment from "moment";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";

const SalesReportDetail = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [itemList, setItemList] = useState(location.state.data.content);

  const [reportTitle, setReportTitle] = useState(location.state.reportTitle);
  const [itemStatus, setItemStatus] = useState(location.state.status);
  const [total, setTotal] = useState(location.state.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState(location.state.totalPages);

  const fetchReportDataByStatus = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam = "?status=" + itemStatus ;

      const rs = await fetchRecords(
        AUCTION_ITEM_SALES_REPORT_DETAILS_URL +
          statusParam +
          pagingParams
      );

      console.log("Sales details", rs);

      setItemList(rs.content);
      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchReportDataByStatus();
  }, [pageSize, currentPage]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading title={reportTitle} headingSize="sm">
                <Link to="/sales-report" className="font-size-16">
                  Back to Main Report
                </Link>
              </PlaceholderHeading>

              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12 mt-3">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <SalesReportDetail.TableBodyRow
                          key={i}
                          auctionID={item.auctionID}
                          itemName={item.itemName}
                          sellerId={item.sellerId}
                          sellerName={item.sellerName}
                          currency={item.currency}
                          startDate={item.startDate}
                          endDate={item.endDate}
                          winningBidderId={item.winningBidderId}
                          winningBidderName={item.winningBidderName}
                          finalSalePrice={item.finalSalePrice}
                          paymentStatus={item.paymentStatus}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <SalesReportDetail.MobileBodyRow
                          key={i}
                          auctionID={item.auctionID}
                          itemName={item.itemName}
                          sellerId={item.sellerId}
                          sellerName={item.sellerName}
                          currency={item.currency}
                          startDate={item.startDate}
                          endDate={item.endDate}
                          winningBidderId={item.winningBidderId}
                          winningBidderName={item.winningBidderName}
                          finalSalePrice={item.finalSalePrice}
                          paymentStatus={item.paymentStatus}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

SalesReportDetail.TableBodyRow = ({
  auctionID,
  itemName,
  sellerId,
  sellerName,
  currency,
  startDate,
  endDate,
  winningBidderId,
  winningBidderName,
  finalSalePrice,
  paymentStatus,
}) => {
  return (
    <>
      <tr>
        <td>
          <div className={tableClasses.label}>Auction Id</div>
          <div className={tableClasses.value}>{auctionID}</div>
          <div className={tableClasses.label}>Item Name</div>
          <div className={tableClasses.value}>{itemName}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Seller Id</div>
          <div className={tableClasses.value}>{sellerId}</div>
          <div className={tableClasses.label}>Seller Name</div>
          <div className={tableClasses.value}>{sellerName}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Start Date</div>
          <div className={tableClasses.value}>{startDate} </div>
          <div className={tableClasses.label}>End Date</div>
          <div className={tableClasses.value}>{endDate}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Winning Bidder ID</div>
          <div className={tableClasses.value}>
              {winningBidderId ? winningBidderId : "n/a"}
          </div>
          <div className={tableClasses.label}>Winning Bidder Name</div>
          <div className={tableClasses.value}>
              {winningBidderName ? winningBidderName : "n/a"}
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Final Sale Price</div>
          <div className={tableClasses.value}>{finalSalePrice ? finalSalePrice : "n/a"}</div>
          <div className={tableClasses.label}>Payment Status</div>
          <div className={tableClasses.value}>
          {paymentStatus && (paymentStatus.replaceAll("_", " "))}
          </div>
        </td>
      </tr>
    </>
  );
};

SalesReportDetail.MobileBodyRow = ({
  auctionID,
  itemName,
  sellerId,
  sellerName,
  currency,
  startDate,
  endDate,
  winningBidderId,
  winningBidderName,
  finalSalePrice,
  paymentStatus,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={`${tableClasses.blockText} w-100`}>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Auction Id: </div>
            <div className={tableClasses.value}> {auctionID}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Item Name</div>
            <div className={tableClasses.value}>{itemName}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Seller Id</div>
            <div className={tableClasses.value}>{sellerId}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Seller Name</div>
            <div className={tableClasses.value}>{sellerName}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Start Date</div>
            <div className={tableClasses.value}>{startDate} </div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>End Date</div>
            <div className={tableClasses.value}>{endDate}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Winning Bidder ID</div>
            <div className={tableClasses.value}>
                {winningBidderId ? winningBidderId : "n/a"}
            </div>
          </div>
          <div className={tableClasses.item}>  
            <div className={tableClasses.label}>Winning Bidder Name</div>
            <div className={tableClasses.value}>
                {winningBidderName ? winningBidderName : "n/a"}
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Final Sale Price</div>
            <div className={tableClasses.value}>{finalSalePrice ? finalSalePrice : "n/a"}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Status</div>
            <div className={tableClasses.value}>
              {paymentStatus && (paymentStatus.replaceAll("_", " "))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReportDetail;
