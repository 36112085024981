import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import tableClasses from "../../components/table/LotsTable.module.css";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import {
  allAdminRoles,
  allAdminUsers,
  createUser,
} from "../../services/AdmminDashboardServices";
import { useEffect } from "react";
import { Field, Formik } from "formik";
import {
  Regex,
  validatePassword,
  validateUsername,
} from "../../services/Utilities";
import * as Yup from "yup";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PasswordGroup } from "../../components/PasswordGroup";
import { Alert } from "@mui/material";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";

const AddAdminUser = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const [serverErrors, setServerErrors] = useState();
  const [roles, setRoles] = useState([]);
  const [checkedEmailPrefrences, setCheckedEmailPrefrences] = useState([]);
  const [emailPrefrences, setEmailPrefrences] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const schema = Yup.object().shape({
    firstname: Yup.string()
      .required("First name is required")
      .min(3, "First name must be minimum of 3 characters")
      .max(40, "First name must be maximum of 40 characters")
      .matches(
        Regex.stringWithSpace,
        "First name must contain characters and space only"
      ),
    lastname: Yup.string()
      .required("Last name is required")
      .min(3, "Last name must be minimum of 3 characters")
      .max(20, "Last name must be maximum of 20 characters")
      .matches(
        Regex.stringWithSpace,
        "Last name must contain characters and space only"
      ),
    username: Yup.string()
      .max(60, "Username must be maximum of 60 characters")
      .required("Username is required"),
    password: Yup.string()
      .min(8, "Please read our password policy. ")
      .max(20, "Password must be maximum of 20 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with password"
      ),
  });

  const initialValue = {
    username: "",
    firstname: "",
    lastname: "",
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    ctx.showProgress(true);
    allAdminRoles()
      .then((res) => {
        console.log(res);
        setRoles(res);
        setCheckedEmailPrefrences(new Array(res.length).fill(false));
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  }, []);

  const createAccount = (values) => {
    values.roles = checkedEmailPrefrences
      .flatMap((bool, index) => (bool ? index : []))
      .map((i) => roles[i]);
    let payload  = values;
    delete payload.confirmPassword;
    console.log(payload);
    ctx.showProgress(true);
    createUser(payload)
      .then((res) => {
        debugger;
        ctx.showProgress(false);
        setError(false);
        setSuccess("User created successfully");
      })
      .catch((error) => {
        let err = JSON.parse(error.message);
        setError(err);
        setSuccess(false);
        ctx.showProgress(false);
      });
  };

  const passwordTooltip = (
    <div className="tooltip-info">
      <h5>Password Policy</h5>
      <p className="mr-10">
        Password must be atleast 8 characters and must contain an upper-case
        character, a lower-case character, a numeric character, and a special
        character.
      </p>
    </div>
  );

  const handleEmailPrefrencesOnChange = (position) => {
    let prefValues = {};
    prefValues = checkedEmailPrefrences
      .flatMap((bool, index) => (bool ? index : []))
      .map((i) => roles[i]);

    const updatedCheckedPrefrences = checkedEmailPrefrences.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedEmailPrefrences(updatedCheckedPrefrences);

    prefValues = updatedCheckedPrefrences
      .flatMap((bool, index) => (bool ? index : []))
      .map((i) => roles[i]);

    setEmailPrefrences(prefValues);
  };

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu userManagementActive />
            </div>
            <div className="offset-lg-1 col-lg-8 col-md-8">
              <h2 className="h4 font-weight-bold">Create User</h2>
              <div className="mt-3 mb-5">
                <BHotrizontalProgress progress={33} />
              </div>
              {error && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <label className={{color:'red'}}>
                      <Alert severity="error">{error?.data?.message}</Alert>
                    </label>
                  </div>
                </div>
              )}
              {success && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <label className={{color:'green'}}>
                      <Alert severity="success">{success}</Alert>
                    </label>
                  </div>
                </div>
              )}
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={schema}
                onSubmit={(values) => {
                  createAccount(values);
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  validateForm,
                  setFieldValue,
                }) => (
                  <form id="passportInfoForm">
                    <div className="row">
                      <div className="offset-lg-2 col-lg-8 col-md-8">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                type="text"
                                name="username"
                                required="true"
                                component={ZInputComponent}
                                placeholder="Enter username"
                                label="Username"
                                serverError={
                                  serverErrors && serverErrors.userName
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* first row */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                type="text"
                                name="firstname"
                                required="true"
                                component={ZInputComponent}
                                placeholder="Enter first name"
                                label="First Name"
                                serverError={
                                  serverErrors && serverErrors.firstName
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                type="text"
                                name="lastname"
                                required="true"
                                component={ZInputComponent}
                                placeholder="Enter last name"
                                label="Last Name"
                                serverError={
                                  serverErrors && serverErrors.lastName
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* first row end */}

                        {/* 3 row */}

                        <div className="row">
                          <div className="col-md-12">
                            {/* <ReactTooltip
                              id="passwordTip"
                              place="top"
                              className="tooltip-theme"
                            >
                              {passwordTooltip}
                            </ReactTooltip> */}
                            {passwordTooltip}
                          </div>
                          <div className="col-md-6">
                            <div className="js-form-message mb-4">
                              <label className="form-label">
                                Password<span className="color-red"> *</span>
                              </label>
                              <Field
                                name="password"
                                component={PasswordGroup}
                                serverError={
                                  serverErrors && serverErrors.password
                                }
                                placeholder="Password"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="js-form-message mb-4">
                              <label className="form-label">
                                Confirm Password
                                <span className="color-red"> *</span>
                              </label>
                              <Field
                                name="confirmPassword"
                                component={PasswordGroup}
                                serverError={
                                  serverErrors && serverErrors.password
                                }
                                placeholder="Re-enter password"
                              />
                            </div>
                          </div>
                          <div className="col-md-12" style={{textTransform: "lowercase"}}>
                            {roles.map((value, index) => {
                              return (
                                <div
                                  className="col-md-6"
                                  key={index}
                                  style={{ display: "block" }}
                                >
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`ep-checkbox-${index}`}
                                      name="emailPrefrences"
                                      value={value}
                                      checked={checkedEmailPrefrences[index]}
                                      onChange={() =>
                                        handleEmailPrefrencesOnChange(index)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`ep-checkbox-${index}`}
                                    >
                                      {value.replace(/_/g, " ")}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* 3 row end */}

                        <div className="row mb-12 mt-5">
                          <div className="col-md-12">
                            <Link
                              className="btn-block btn btn-primary-dark-w px-5"
                              type="submit"
                              onClick={async (e) => {
                                e.preventDefault();
                                let res = await validateForm();
                                console.log(res);
                                // debugger;
                                if (res && Object.values(res)?.length > 0) {
                                }
                                handleSubmit(e);
                              }}
                            >
                              Create User
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddAdminUser;
