import "./App.css";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import Login from "./pages/login/login";
import { useAuthContext } from "./redux/auth-context";
import { useEffect } from "react";
import LoadingModal from "./components/UI/LoadingModal";
import { shallowEqual, useSelector } from "react-redux";
import ErrorPage from "./pages/errors/ErrorPage";
import Error404 from "./pages/errors/Error404";
import NotAuthorized from "./pages/errors/NotAuthorized";
import ViewExpired from "./pages/errors/ViewExpired";
import CyberAttack from "./pages/errors/CyberAttack";
import Logout from "./pages/login/logout";
import Loader from "../src/assets/images/loader.gif";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/user-management/Users";
import SellerAccountList from "./pages/seller-approval/SellerAccountList";
import SellerDecision from "./pages/seller-approval/SellerDecision";
import AddAdminUser from "./pages/user-management/AddAdminUser";
import EditUser from "./pages/user-management/EditUser";
import IdentityVerificationRequests from "./pages/identity-verification/IdentityVerificationRequests";
import VerificationDecision from "./pages/identity-verification/VerificationDecision";
import DemoRequests from "./pages/schedule-demo/DemoRequests";
import DemoDecision from "./pages/schedule-demo/DemoDecision";
import ViewDemoDetails from "./pages/schedule-demo/ViewDemoDetails";
import Config from "./pages/config-management/Config";
import Contact from "./pages/config-management/Contact";
import ContactDetails from "./pages/config-management/ContactDetails";
import ProbationConfig from "./pages/config-management/ProbationConfig";
import { hasAdminRole } from "./services/Utilities";
import SellerOverviewReport from "./pages/seller-reports/SellerOverviewReport";
import SellerOverviewReportDetail from "./pages/seller-reports/SellerOverviewReportDetail";
import AuctionListingReport from "./pages/seller-reports/AuctionListingReport";
import SalesReport from "./pages/seller-reports/SalesReport";
import SalesReportDetail from "./pages/seller-reports/SalesReportDetail";
import AuctionItemList from "./pages/payment-confirmation/AuctionItemList";
import ConfirmPayment from "./pages/payment-confirmation/ConfirmPayment";
import RegisteredSellerDetail from "./pages/registered-seller/RegisteredSellerDetail";
import RegisteredSellers from "./pages/registered-seller/RegisteredSellers";
import SellerDetails from "./pages/seller-reports/SellerDetails";
import RegisteredBidderDetail from "./pages/registered-bidder/RegisteredBidderDetail";
import RegisteredBidders from "./pages/registered-bidder/RegisteredBidders";
import Main from "./pages/Main";
import RegisteredSellerFeeUpdate from "./pages/registered-seller/RegisteredSellerFeeUpdate";

function App() {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const changeRouteEventHandler = (e) => {
    const { detail: path } = e;
    navigate(path);
  };

  useEffect(() => {
    window.addEventListener("changeroute", changeRouteEventHandler);
    return () => {
      window.removeEventListener("changeroute", changeRouteEventHandler);
    };
  }, []);

  const { isAuthorized, viewExpired, roles } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      viewExpired: auth.viewExpired,
      roles: auth.roles,
    }),
    shallowEqual
  );

  return (
    <>
      {ctx.isShowProgress && <LoadingModal waitImg={Loader} />}

      <Routes>
        <Route path="/view-expired" element={<ViewExpired />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/too-many-requests" element={<CyberAttack />} />

        {isAuthorized && (
          <>
            <Route path="/main" element={<Main />} />
            <Route path="/dashboard" element={<Dashboard />} />

            {hasAdminRole(roles, "USER_MANAGEMENT") && (
              <>
                <Route path="/users" element={<Users />} />
                <Route path="/add-admin-user" element={<AddAdminUser />} />
                <Route path="/update-user/:user" element={<EditUser />} />
              </>
            )}

            {hasAdminRole(roles, "CONFIGURATION_MANAGEMENT") && (
              <Route path="/configurations" element={<Config />} />
            )}

            {hasAdminRole(roles, "CONTACT_US_MANAGEMENT") && (
              <>
                <Route path="/contact-us-request" element={<Contact />} />
                <Route path="/contact-details" element={<ContactDetails />} />
              </>
            )}

            {/* <Route path="/probation-configurations" element={<ProbationConfig />} /> */}

            {hasAdminRole(roles, "SELLER_MANAGEMENT") && (
              <>
                <Route
                  path="/seller-approval-requests"
                  element={<SellerAccountList />}
                />
                <Route
                  path="/seller-approval/:sellerId"
                  element={<SellerDecision />}
                />

                <Route
                  path="/registered-sellers"
                  element={<RegisteredSellers />}
                />
                <Route
                  path="/registered-seller/:sellerId"
                  element={<RegisteredSellerDetail />}
                />

                <Route
                  path="/buyer-premium/:sellerId"
                  element={<RegisteredSellerFeeUpdate />}
                />

                <Route
                  path="/seller-activity-report"
                  element={<SellerOverviewReport />}
                />
                <Route
                  path="/seller-activity-details"
                  element={<SellerOverviewReportDetail />}
                />
                <Route
                  path="/seller-activity-report/:sellerId"
                  element={<SellerDetails />}
                />

                <Route path="/demo-requests" element={<DemoRequests />} />
                <Route
                  path="/demo-approval/:requestId"
                  element={<DemoDecision />}
                />
                <Route
                  path="/demo-detail/:requestId"
                  element={<ViewDemoDetails />}
                />

                <Route path="/manage-payments" element={<AuctionItemList />} />
                <Route
                  path="/confirm-payment/:item"
                  element={<ConfirmPayment />}
                />
              </>
            )}

            {hasAdminRole(roles, "BIDDER_MANAGEMENT") && (
              <>
                <Route
                  path="/identity-verification-requests"
                  element={<IdentityVerificationRequests />}
                />
                <Route
                  path="/identity-verification/:userId"
                  element={<VerificationDecision />}
                />

                <Route
                  path="/registered-bidders"
                  element={<RegisteredBidders />}
                />
                <Route
                  path="/registered-bidder/:bidderId"
                  element={<RegisteredBidderDetail />}
                />
              </>
            )}

            {hasAdminRole(roles, "REPORTS") && (
              <>
                <Route
                  path="/auction-listing-report"
                  element={<AuctionListingReport />}
                />
                <Route path="/sales-report" element={<SalesReport />} />
                <Route path="/sales-details" element={<SalesReportDetail />} />
              </>
            )}

            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<Navigate to="/main" />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </>
        )}

        {!isAuthorized && (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Navigate to="/" />} />

            <Route path="/" element={<Navigate to="/login" />} />

            <Route
              path="*"
              element={
                viewExpired ? <Navigate to="/" /> : <Navigate to="/404" />
              }
            />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
