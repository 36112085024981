import {
  CONFIRM_PAYMENT,
  INVOICE_ACCOUNT_INFO_URL,
  INVOICE_CARD_PAYMENT_INIT_URL,
  INVOICE_CARD_PAYMENT_SUCESS_URL,
  WIRE_INVOICE_RECEIPT_UPLOAD_URL,
} from "../Constants";
import authenticatedFetch from "./fetch";

export async function fetchData(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function paymentConfirmation(itemId) {
  const response = await authenticatedFetch(
    `${CONFIRM_PAYMENT.replace("{itemId}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}
