import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Seller.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import LotsTable from "../../components/table/LotsTable";
import {
  deflateErrors,
  formatUSD,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Label from "../../components/UI/Label";
import {
  SELLER_APPROVAL_DETAIL_URL,
  SELLER_APPROVAL_DECISION_URL,
} from "../../Constants";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";
import { fetchRecords, sellerApproval } from "../../services/SellerService";
import * as Yup from "yup";
import ErrorMessage from "../../components/UI/ErrorMessage";
import ViewField from "../../components/UI/ViewField";

const SellerDecision = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [detail, setDetail] = useState({});

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showRejectConfirmBox, setShowRejectConfirmBox] = useState(false);
  const [showDeferConfirmBox, setShowDeferConfirmBox] = useState(false);

  const [remarksValue, setRemarksValue] = useState();
  const [buyerFeeValue, setBuyerFeeValue] = useState();

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();

  const fetchData = async (sellerId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchRecords(
        SELLER_APPROVAL_DETAIL_URL.replace("{sellerId}", sellerId)
      );
      console.log("item", rs);
      setDetail(rs);
      setBuyerFeeValue(rs?.seller?.buyerFee);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const approveAccount = async (userId) => {
    ctx.showProgress(true);
    await approvalDecision({
      userId: userId,
      remarks: remarksValue,
      buyerFee: buyerFeeValue,
      decision: "APPROVE",
    });
  };

  const deferAccount = async (userId) => {
    ctx.showProgress(true);
    await approvalDecision({
      userId: userId,
      remarks: remarksValue,
      decision: "DEFER",
    });
  };

  const rejectAccount = async (userId) => {
    ctx.showProgress(true);
    await approvalDecision({
      userId: userId,
      remarks: remarksValue,
      decision: "REJECT",
    });
  };

  const approvalDecision = async (requestBody) => {
    sellerApproval(requestBody)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/seller-approval-requests");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;

        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        const json = JSON.parse(e.message);
        if (json.status !== "BAD_REQUEST") {
          return;
        }

        if (json.data.status === "VALIDATION_FAILED") {
          setServerErrors({
            ...deflateErrors(json.data.errors),
          });
        }
      });
  };

  const validateForm = () => {
    if (!remarksValue || remarksValue.trim() == "") {
      setServerErrors({ remarks: "Please enter approval remarks" });

      return false;
    }

    return true;
  };

  const validateApprove = () => {
    if (!buyerFeeValue || buyerFeeValue.trim() == "") {
      setServerErrors({ buyerFee: "Please enter buyer premium" });
      return false;
    }

    if (parseInt(buyerFeeValue) < 0 || parseInt(buyerFeeValue) > 100) {
      setServerErrors({
        buyerFee: "Please enter valid buyer premium",
      });
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (params.sellerId) fetchData(params.sellerId);
  }, [params.sellerId]);

  const tableHeader = (
    <tr>
      <th></th>
      <th>Remarks</th>
      <th>Decision</th>
      <th>Submitted On</th>
      <th>Approval By</th>
    </tr>
  );

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to approve this seller account?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            approveAccount(detail?.userId);
          }}
        />
      )}

      {showRejectConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to reject this seller account?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
            rejectAccount(detail?.userId);
          }}
        />
      )}

      {showDeferConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to defer this seller account approval?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowDeferConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowDeferConfirmBox(false);
            deferAccount(detail?.userId);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                sellerManagementActive
                subMenu={"seller-account-approval"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Seller Account Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col">
                  <h4>
                    {detail?.firstName} {detail?.lastName}
                  </h4>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Email Address" value={detail?.email} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Applied On" value={detail?.createdOn} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Source"
                        value={detail?.creationSource}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Certificate Number"
                        value={detail?.seller?.certificateNumber}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Seller Type"
                        value={detail?.seller?.type}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Agency"
                        value={
                          detail?.seller?.type == "AGENCY"
                            ? detail?.seller?.agencyName
                            : "N/A"
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Street"
                        value={detail?.seller?.street}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="P.O Box"
                        value={detail?.seller?.poBox}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Zip Code"
                        value={detail?.seller?.zipCode}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="City"
                        value={detail?.seller?.city?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="State"
                        value={detail?.seller?.state?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Country"
                        value={detail?.seller?.country?.value}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col mt-5 mb-5">
                  <h5>Approval History</h5>
                  <LotsTable
                    tableHeader={tableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={5} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      detail?.approvalHistory &&
                      Object.keys(detail?.approvalHistory).length > 0
                        ? detail?.approvalHistory.map((hist, i) => {
                            return (
                              <SellerDecision.HistoryBodyRow
                                key={i}
                                number={i + 1}
                                remarks={hist.remarks}
                                submittedOn={hist.createdOn}
                                approvalBy={hist.approvalBy}
                                approvalStatus={hist.status}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      detail?.approvalHistory &&
                      Object.keys(detail?.approvalHistory).length > 0
                        ? detail?.approvalHistory.map((hist, i) => {
                            return (
                              <SellerDecision.HistoryMobileBodyRow
                                key={i}
                                number={i + 1}
                                remarks={hist.remarks}
                                submittedOn={hist.createdOn}
                                approvalBy={hist.approvalBy}
                                approvalStatus={hist.status}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div> */}

              <div className="mt-5">
                <div className="row">
                  <div className="col-md-4 col-12 my-2">
                    <div className="js-form-message">
                      <Label hightlighted>Buyer's Premium</Label>
                      <input
                        type="number"
                        className="form-control"
                        name="buyerFeeValue"
                        placeholder="Enter buyer's premium"
                        value={buyerFeeValue}
                        onChange={(e) => {
                          setBuyerFeeValue(e?.target?.value);
                        }}
                      />
                    </div>

                    {serverErrors?.buyerFee && (
                      <ErrorMessage>{serverErrors?.buyerFee}</ErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className="js-form-message">
                  <Label hightlighted>Remarks</Label>
                  <textarea
                    className="form-control"
                    name="remarks"
                    placeholder="Enter approval remarks"
                    onChange={(e) => {
                      setRemarksValue(e?.target?.value);
                    }}
                  ></textarea>
                </div>

                {serverErrors?.remarks && (
                  <ErrorMessage>{serverErrors?.remarks}</ErrorMessage>
                )}
              </div>

              <div className="mt-5">
                <ButtonsPanel>
                  <a
                    href="#"
                    className={`btn btn-secondary ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!validateForm()) {
                        return;
                      }

                      setShowRejectConfirmBox(true);
                    }}
                  >
                    REJECT ACCOUNT
                  </a>

                  {/* <a
                    href="#"
                    className={`btn btn-secondary ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!validateForm()) {
                        return;
                      }

                      setShowDeferConfirmBox(true);
                    }}
                  >
                    DEFER APPROVAL
                  </a> */}

                  <a
                    href="#"
                    className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!validateApprove()) {
                        return;
                      }

                      setShowConfirmBox(true);
                    }}
                  >
                    APPROVE ACCOUNT
                  </a>
                </ButtonsPanel>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

SellerDecision.HistoryBodyRow = ({
  number,
  remarks,
  submittedOn,
  approvalBy,
  approvalStatus,
}) => (
  <>
    <tr>
      <td>{number}</td>
      <td>{remarks}</td>
      <td>{approvalStatus}</td>
      <td>{submittedOn}</td>
      <td>{approvalBy}</td>
    </tr>
  </>
);

SellerDecision.HistoryMobileBodyRow = ({
  number,
  remarks,
  submittedOn,
  approvalBy,
  approvalStatus,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}></div>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Remarks</div>
            <div className={tableClasses.value}>{remarks}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Decision</div>
            <div className={tableClasses.value}>{approvalStatus}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Submitted On</div>
            <div className={tableClasses.value}>{submittedOn}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Approval By</div>
            <div className={tableClasses.value}>{approvalBy}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerDecision;
