import React from "react";
import classes from "./dashboard.module.css";

const DashboardCard = ({ children, height, bottomPadding = true, topPadding = true }) => {
  const cardHeightStyle = height ? { height: height + "px" } : {};
  const cardBottomPaddingStyle = bottomPadding ? {} : { paddingBottom: "0px" };
  const cardTopPaddingStyle = topPadding ? {} : { paddingTop: "0px" };

  const styles = { ...cardHeightStyle, ...cardBottomPaddingStyle, ...cardTopPaddingStyle };

  return (
    <div className={classes.dashboardCard} style={styles}>
      {children}
    </div>
  );
};

export default DashboardCard;
