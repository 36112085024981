import authenticatedFetch from "./fetch";
import { AUCTION_CONFIG, AUCTION_CONFIG_UPDATE, AUCTION_PROBATION_CONFIG, AUCTION_PROBATION_UPDATE } from "../Constants";

export async function allAuctionConfig() {
  const response = await authenticatedFetch(`${AUCTION_CONFIG}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function allProbationConfig() {
  const response = await authenticatedFetch(`${AUCTION_PROBATION_CONFIG}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export const updateAuctionConfig = async (requestBody) => {
  const response = await authenticatedFetch(`${AUCTION_CONFIG_UPDATE}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 201 && response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const updateAuctionProbation = async (requestBody) => {
  const response = await authenticatedFetch(`${AUCTION_PROBATION_UPDATE}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 201 && response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export async function fetchData(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export const updateData = async (requestBody) => {
  const response = await authenticatedFetch(`${AUCTION_CONFIG_UPDATE}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const deleteData = async (URL) => {
  const response = await authenticatedFetch(`${URL}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};
