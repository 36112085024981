import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "../seller-approval/Seller.module.css";
import {
  deflateErrors,
  formatUSD,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Label from "../../components/UI/Label";
import {
  SELLER_DETAIL_URL,
} from "../../Constants";
import { fetchRecords, sellerApproval } from "../../services/SellerService";
import * as Yup from "yup";
import ErrorMessage from "../../components/UI/ErrorMessage";
import ViewField from "../../components/UI/ViewField";
import PlaceholderHeading from "../../components/UI/heading/PlaceholderHeading";

const SellerDetails = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [detail, setDetail] = useState({});

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();

  const fetchData = async (sellerId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchRecords(
        SELLER_DETAIL_URL.replace("{sellerId}", sellerId)
      );
      console.log("item", rs);
      setDetail(rs);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    if (params.sellerId) fetchData(params.sellerId);
  }, [params.sellerId]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">

            <div className="col-lg-12 col-md-12">
              <PlaceholderHeading title="Seller Details" headingSize="sm" >
                <Link to="/seller-activity-report" className="font-size-16">
                  Back to Main Report
                </Link>
              </PlaceholderHeading>
              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col-md-6">
                  <div className="col-12 text-center">
                      <img className="w-10 border border-dark rounded-sm" style={{ height: "130px" }} src={detail?.seller?.sellerLogo} alt="seller logo" />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField label="ID | Name" value={detail?.userId +" | "+detail?.firstName +" "+ detail?.lastName} />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField label="Email Address" value={detail?.email} />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField label="Account Creation Date" value={detail?.createdOn} />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField label="Account Approval Date" value={detail?.seller?.accountApprovalDate} />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField label="Contact Type" value={detail?.seller?.contactType} />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField label="Contact Number" value={detail?.seller?.contactNumber} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12 my-2">
                    <ViewField label="Seller Type" value={detail?.seller?.type} />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField
                      label="Agency"
                      value={detail?.seller?.agencyName}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField
                      label="Street"
                      value={detail?.seller?.street}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField
                      label="P.O Box"
                      value={detail?.seller?.poBox}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField
                      label="Zip Code"
                      value={detail?.seller?.zipCode}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField
                      label="City"
                      value={detail?.seller?.city?.value}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField
                      label="State"
                      value={detail?.seller?.state?.value}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <ViewField
                      label="Country"
                      value={detail?.seller?.country?.value}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SellerDetails;
