import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Bidder.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import LotsTable from "../../components/table/LotsTable";
import {
  deflateErrors,
  formatUSD,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Label from "../../components/UI/Label";
import {
  REGISTERED_BIDDER_DETAIL_URL,
  REGISTERED_BIDDER_STATUS_ACTIVE_URL,
  REGISTERED_BIDDER_STATUS_BAN_URL,
} from "../../Constants";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";
import {
  fetchRecords,
  bidderLoginStatusUpdate,
} from "../../services/BidderService";
import * as Yup from "yup";
import ErrorMessage from "../../components/UI/ErrorMessage";
import ViewField from "../../components/UI/ViewField";

const RegisteredBidderDetail = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [detail, setDetail] = useState({});

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showRejectConfirmBox, setShowRejectConfirmBox] = useState(false);

  const [serverErrors, setServerErrors] = useState();

  const fetchData = async (bidderId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchRecords(
        REGISTERED_BIDDER_DETAIL_URL.replace("{bidderId}", bidderId)
      );
      console.log("item", rs);
      setDetail(rs);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const activateAccount = async (userId) => {
    ctx.showProgress(true);
    await approvalDecision(
      REGISTERED_BIDDER_STATUS_ACTIVE_URL.replace("{bidderId}", userId)
    );
  };

  const banAccount = async (userId) => {
    ctx.showProgress(true);
    await approvalDecision(
      REGISTERED_BIDDER_STATUS_BAN_URL.replace("{bidderId}", userId)
    );
  };

  const approvalDecision = async (URL) => {
    bidderLoginStatusUpdate(URL)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/registered-bidders");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;

        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        const json = JSON.parse(e.message);
        if (json.status !== "BAD_REQUEST") {
          return;
        }

        if (json.data.status === "VALIDATION_FAILED") {
          setServerErrors({
            ...deflateErrors(json.data.errors),
          });
        }
      });
  };

  useEffect(() => {
    if (params.bidderId) fetchData(params.bidderId);
  }, [params.bidderId]);

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to activate this bidder account?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            activateAccount(detail?.userId);
          }}
        />
      )}

      {showRejectConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to ban this bidder account?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
            banAccount(detail?.userId);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu
                bidderManagementActive
                subMenu={"registered-bidders"}
              />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Bidder Account Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col">
                  <h4>
                    {detail?.firstName} {detail?.lastName}
                  </h4>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Email Address" value={detail?.email} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField label="Applied On" value={detail?.createdOn} />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Source"
                        value={detail?.creationSource}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Company"
                        value={detail?.bidder?.company ? detail?.bidder?.company : "n/a"}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="VAT Registration"
                        value={detail?.bidder?.vatRegistration
                          ? detail?.bidder?.vatRegistration
                          : "n/a"}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Zip Code"
                        value={detail?.bidder?.zipCode}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="City"
                        value={detail?.bidder?.city?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="State"
                        value={detail?.bidder?.state?.value}
                      />
                    </div>
                    <div className="col-md-4 col-12 my-2">
                      <ViewField
                        label="Country"
                        value={detail?.bidder?.country?.value}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <ButtonsPanel>
                  <a
                    href="#"
                    className={`btn btn-secondary-dark-w ${buttonPanelClasses.item}`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/registered-bidders");
                    }}
                  >
                    CANCEL
                  </a>

                  {detail?.status === "ACTIVE" && (
                    <a
                      href="#"
                      className={`btn btn-secondary ${buttonPanelClasses.item}`}
                      onClick={(e) => {
                        e.preventDefault();

                        setShowRejectConfirmBox(true);
                      }}
                    >
                      BAN BIDDER
                    </a>
                  )}

                  {detail?.status !== "ACTIVE" && (
                    <a
                      href="#"
                      className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmBox(true);
                      }}
                    >
                      ACITIVATE BIDDER
                    </a>
                  )}
                </ButtonsPanel>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

RegisteredBidderDetail.HistoryBodyRow = ({
  number,
  remarks,
  submittedOn,
  approvalBy,
  approvalStatus,
}) => (
  <>
    <tr>
      <td>{number}</td>
      <td>{remarks}</td>
      <td>{approvalStatus}</td>
      <td>{submittedOn}</td>
      <td>{approvalBy}</td>
    </tr>
  </>
);

RegisteredBidderDetail.HistoryMobileBodyRow = ({
  number,
  remarks,
  submittedOn,
  approvalBy,
  approvalStatus,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}></div>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Remarks</div>
            <div className={tableClasses.value}>{remarks}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Decision</div>
            <div className={tableClasses.value}>{approvalStatus}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Submitted On</div>
            <div className={tableClasses.value}>{submittedOn}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Approval By</div>
            <div className={tableClasses.value}>{approvalBy}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredBidderDetail;
